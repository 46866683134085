import React, { useState } from "react";
import { withFormik, FormikProps, Form, FormikErrors } from "formik";
import { IntlShape } from "react-intl";

import { TextFieldComponent } from "@app/core";
import { SelectComponent } from "@app/core/select";
import { CreateProductDTO, ImageDTO, InProductDTO, OutProductDTO, SeoDTO } from "@app/api/generated";
import { platformType, getTranslatedPlatforms } from "@app/constants/platform";
import { getTranslatedStatusses } from "@app/constants/status";
import { storeStatusType } from "@app/api/core/merchant/merchant";
import { FormGroup } from "@app/core/form-group/form-group";
import { FormRow } from "@app/core/form-row/form-row";
import { FormField } from "@app/core/form-field/form-field";
import { ImageUpload } from "@app/core/image-upload/image-upload.component";
import { FormColumn } from "@app/core/form-column/form-column";
import { RichEditor } from "@app/core/rich-editor";
import { SlugifyTextField } from "@app/core/slugify-text-field/slugify-text-field";
import { basicSlugify } from "@app/util/slugify";
import { FormActions } from "@app/components/form-actions/form-actions";

import styles from "./product-form.module.scss";

interface IFormValues {
  title: string;
  slug: string;
  status: storeStatusType;
  platform: platformType;
  image?: ImageDTO;
  seo: SeoDTO;
  description?: string;
  specification?: string;
  ean?: string;
}

const InnerForm = (props: IMyFormProps & FormikProps<IFormValues>) => {
  const { touched, errors, values, setFieldValue, onEdit } = props;
  const translatedPlatforms = getTranslatedPlatforms(props.intl);
  const translatedStatus = getTranslatedStatusses(props.intl);
  const [userChangedSlug, setUserChangedSlug] = useState<boolean>(false);

  const onCustomChange = (value: any, id: string) => {
    handleChange(value);
    setFieldValue(id, value);
  };

  const handleChange = (value: any) => {
    props.handleChange(value);
    onEdit();
  };

  const handlePlatformChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    handleChange(e);
  };

  const handleSlugChange = (e?: React.ChangeEvent<HTMLInputElement>, disableSlugify?: boolean): void => {
    const value = !e ? values.title : e.currentTarget.value;
    const newValue = disableSlugify ? value : basicSlugify(value, true);

    if (newValue !== values.slug) {
      handleChange(newValue);
      setFieldValue("slug", newValue);
    }
  };

  return (
    <Form>
      <div className={styles.form}>
        <FormGroup title={props.intl.formatMessage({ id: "products.detail.form.formGroup.statusPlatform.title" })}>
          <FormRow>
            <FormField>
              <TextFieldComponent
                id="title"
                onBlur={props.handleBlur}
                value={values.title}
                onChange={(e) => {
                  handleChange(e);

                  if (props.isCreating && !userChangedSlug) {
                    handleSlugChange(e as any);
                  }
                }}
                label={{
                  label: props.intl.formatMessage({ id: "products.detail.form.title.label" }),
                  errorMessage: touched.title && errors.title
                }}
              />
            </FormField>
            <FormField>
              <SlugifyTextField
                id="slug"
                value={values.slug}
                onChange={(e) => {
                  setUserChangedSlug(true);
                  handleSlugChange(e as any, true);
                }}
                onGenerateSlugClick={() => handleSlugChange()}
                onBlur={props.handleBlur}
                label={{
                  label: props.intl.formatMessage({ id: "products.detail.form.slug.label" }),
                  errorMessage: touched.slug && errors.slug
                }}
              />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <SelectComponent
                id="status"
                onBlur={props.handleBlur}
                value={values.status}
                onChange={handleChange}
                options={translatedStatus}
                label={{
                  label: props.intl.formatMessage({ id: "products.detail.form.status.label" }),
                  errorMessage: touched.status && errors.status
                }}
              />
            </FormField>
            <FormField>
              <SelectComponent
                id="platform"
                onBlur={props.handleBlur}
                value={values.platform}
                onChange={handlePlatformChange}
                options={translatedPlatforms}
                label={{
                  label: props.intl.formatMessage({ id: "products.detail.form.platform.label" }),
                  errorMessage: touched.platform && errors.platform
                }}
              />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <TextFieldComponent
                id="ean"
                onBlur={props.handleBlur}
                value={values.ean}
                onChange={handleChange}
                label={{
                  label: props.intl.formatMessage({ id: "products.detail.form.ean.label" }),
                  errorMessage: touched.ean && errors.ean
                }}
                placeholder={props.intl.formatMessage({ id: "products.detail.form.ean.placeholder" })}
                isTextArea
              />
            </FormField>
          </FormRow>
        </FormGroup>

        <FormGroup title={props.intl.formatMessage({ id: "products.detail.form.formGroup.seo.title" })}>
          <FormRow>
            <FormField>
              <RichEditor
                onChange={onCustomChange}
                pageField={props.values.seo?.pageText || ""}
                id="seo.pageText"
                height={215}
                maxHeight={400}
                label={{
                  label: props.intl.formatMessage({ id: "products.detail.form.seoText.label" }),
                  tooltip: props.intl.formatMessage({ id: "products.detail.form.seoText.tooltip" })
                }}
              />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <TextFieldComponent
                id="seo.title"
                onBlur={props.handleBlur}
                value={values.seo.title}
                onChange={handleChange}
                label={{
                  label: props.intl.formatMessage({ id: "products.detail.form.seoTitle.label" }),
                  errorMessage: touched.seo?.title && errors.seo?.title
                }}
              />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <TextFieldComponent
                id="seo.description"
                onBlur={props.handleBlur}
                value={values.seo.description}
                onChange={handleChange}
                label={{
                  label: props.intl.formatMessage({ id: "products.detail.form.seoDescription.label" }),
                  errorMessage: touched.seo?.description && errors.seo?.description
                }}
              />
            </FormField>
          </FormRow>
        </FormGroup>

        <FormGroup title={props.intl.formatMessage({ id: "products.detail.form.formGroup.image.title" })}>
          <FormRow>
            <FormField>
              <ImageUpload
                id="image"
                label={{
                  label: props.intl.formatMessage({ id: "products.detail.form.image.label" })
                }}
                fullWidth
                onChange={onCustomChange}
                image={props.values.image}
              />
            </FormField>
            <FormColumn>
              <FormField>
                <TextFieldComponent
                  label={{
                    label: props.intl.formatMessage({ id: "products.detail.form.imageTitle.label" })
                  }}
                  value={props.values.image && props.values.image.title}
                  onChange={handleChange}
                  onBlur={props.handleBlur}
                  id="image.title"
                  disabled={!props.values.image}
                />
              </FormField>
              <FormField>
                <TextFieldComponent
                  label={{
                    label: props.intl.formatMessage({ id: "products.detail.form.imageAlt.label" })
                  }}
                  value={props.values.image && props.values.image.alt}
                  onChange={handleChange}
                  onBlur={props.handleBlur}
                  id="image.alt"
                  disabled={!props.values.image}
                />
              </FormField>
            </FormColumn>
          </FormRow>
        </FormGroup>

        <FormGroup title={props.intl.formatMessage({ id: "products.detail.form.formGroup.general.title" })}>
          <FormRow>
            <FormField>
              <RichEditor
                onChange={onCustomChange}
                pageField={props.values.description || ""}
                id="description"
                height={215}
                maxHeight={400}
                label={{
                  label: props.intl.formatMessage({ id: "products.detail.form.description.label" }),
                  tooltip: props.intl.formatMessage({ id: "products.detail.form.description.tooltip" })
                }}
              />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <RichEditor
                onChange={onCustomChange}
                pageField={props.values.specification || ""}
                id="specification"
                height={215}
                maxHeight={400}
                label={{
                  label: props.intl.formatMessage({ id: "products.detail.form.specification.label" }),
                  tooltip: props.intl.formatMessage({ id: "products.detail.form.specification.tooltip" })
                }}
              />
            </FormField>
          </FormRow>
        </FormGroup>

        <FormActions
          disableSaveButton={props.disableSaveButton}
          isSubmittingValues={props.isSubmittingValues}
          onCancel={() => props.onCancel(false)}
          onSubmit={props.handleSubmit}
        />
      </div>
    </Form>
  );
};

interface IMyFormProps {
  product?: OutProductDTO;
  disableSaveButton: boolean;
  intl: IntlShape;
  onCancel: (isSavedCheck: boolean) => void;
  onEdit: () => void;
  onSubmit: (values: InProductDTO | CreateProductDTO) => void;
  isSubmittingValues?: boolean;
  isCreating?: boolean;
}

export const ProductForm = withFormik<IMyFormProps, IFormValues>({
  mapPropsToValues: ({ product }) => ({
    title: product?.title || "",
    slug: product?.slug || "",
    status: product?.live ? storeStatusType.live : storeStatusType.offline,
    platform: product?.platform.id || platformType.Nederland,
    image: product?.image,
    seo: product?.seo || { title: "", description: "", pageText: "" },
    ean: product?.ean,
    description: product?.description,
    specification: product?.specification
  }),

  validate: (values: IFormValues, props) => {
    const errors: FormikErrors<IFormValues> = {};

    if (!values.platform) {
      errors.platform = props.intl.formatMessage({ id: "general.form.error.required" });
    }

    if (!values.title) {
      errors.title = props.intl.formatMessage({ id: "general.form.error.required" });
    }

    if (!values.slug) {
      errors.slug = props.intl.formatMessage({ id: "general.form.error.required" });
    }

    if (!values.ean) {
      errors.ean = props.intl.formatMessage({ id: "general.form.error.required" });
    }

    return errors;
  },

  handleSubmit: (values, bag) => {
    const product = mapValuesToProduct(values, bag.props.product);
    bag.props.onSubmit(product);
  }
})(InnerForm);

const mapValuesToProduct = (values: IFormValues, product?: InProductDTO): InProductDTO | CreateProductDTO => {
  const formValues: CreateProductDTO = {
    title: values.title,
    slug: values.slug,
    // eslint-disable-next-line eqeqeq
    live: values.status == storeStatusType.live,
    platform: { id: Number(values.platform), name: "", googleExperimentId: "", wctPlatformId: 0 },
    ean: values.ean,
    seo: values.seo,
    description: values.description,
    specification: values.specification,
    image: values.image
  };

  if (product?.id) {
    return { ...product, ...formValues };
  }

  return formValues;
};
