import React from "react";
import clsx from "clsx";
import { useIntl } from "react-intl";

import { SpinningLoader } from "@app/core/spinning-loader/spinning-loader";
import { Pagination } from "@app/core";

import { PageCard } from "../page-card/page-card";
import styles from "./overview.module.scss";

export interface IOverviewProps<T extends Record<string, any>> extends React.HTMLProps<HTMLDivElement> {
  title?: string;
  actionBar?: React.ReactNode;
  isLoading?: boolean;
  currentPage: number;
  totalPages: number;
  onPageChange?: (page: number) => void;
  overviewItems?: T[];
  header?: React.ReactNode;
  footer?: React.ReactNode;
}

const Overview = <T extends Record<string, any>>({
  className,
  title,
  actionBar,
  children,
  isLoading,
  currentPage,
  totalPages,
  onPageChange,
  overviewItems,
  header,
  footer,
  ...rest
}: IOverviewProps<T>): JSX.Element => {
  const intl = useIntl();

  return (
    <div className={clsx(styles.wrapper, className)} {...rest}>
      <div className={styles.container}>
        {(title || actionBar) && (
          <div className={styles.topBar}>
            {title && <h1>{title}</h1>}
            {actionBar && <div className={styles.actions}>{actionBar}</div>}
          </div>
        )}
        <PageCard>
          {header}
          {overviewItems && overviewItems.length > 0 ? (
            children
          ) : isLoading ? (
            <SpinningLoader />
          ) : (
            <span className={styles.noResults}>{intl.formatMessage({ id: "general.table.noResults" })}</span>
          )}
          {totalPages > 1 && (
            <div className={styles.pagination}>
              <Pagination currentPage={currentPage} totalPages={totalPages} onChange={onPageChange} />
            </div>
          )}
        </PageCard>
        {footer}
      </div>
    </div>
  );
};

export { Overview };
