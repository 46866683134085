import React from "react";
import { IntlShape } from "react-intl";
import { withFormik, FormikProps, FormikErrors, Form } from "formik";

import { TextFieldComponent, ClickableComponent } from "@app/core";
import { SelectComponent } from "@app/core/select";
import { platformType, platformTranslationKeys } from "@app/constants/platform";
import { IFilterType } from "@app/constants/filter";

import styles from "./create-banner-modal-component.module.scss";

export interface ICreateBannerFormValues {
  name: string;
  platform: platformType;
}

interface IOtherProps {
  intl: IntlShape;
  isLoading: boolean;
  onCancel: () => void;
}

const InnerForm = (props: IOtherProps & FormikProps<ICreateBannerFormValues>) => {
  const { touched, errors } = props;

  const translatedPlatforms = platformTranslationKeys.map((untranslatedPlatform) => {
    return {
      id: untranslatedPlatform.id,
      name: props.intl.formatMessage({ id: untranslatedPlatform.name })
    } as IFilterType;
  });

  return (
    <Form>
      <div className={styles.form}>
        <div className={styles.formField}>
          <SelectComponent
            height={40}
            onChange={props.handleChange}
            options={translatedPlatforms}
            id="platform"
            label={{
              label: props.intl.formatMessage({ id: "createBannerForm.input.platform.label" }),
              tooltip: props.intl.formatMessage({ id: "createBannerForm.input.platform.tooltip" })
            }}
          />
        </div>
        <div className={styles.formField}>
          <TextFieldComponent
            placeholder=""
            value={props.values.name}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            type="text"
            id="name"
            errorMessage={touched.name === true && errors.name}
            label={{
              label: props.intl.formatMessage({ id: "createBannerForm.input.name.label" }),
              tooltip: props.intl.formatMessage({ id: "createBannerForm.input.name.tooltip" })
            }}
          />
        </div>
      </div>
      <div className={styles.buttons}>
        <span className={styles.firstButton}>
          <ClickableComponent
            title={props.intl.formatMessage({ id: "createBannerForm.button.cancel" })}
            onClick={() => {
              props.onCancel();
            }}
            height={48}
            variant="primary-inverted"
          />
        </span>
        <span>
          <ClickableComponent
            buttonType="submit"
            title={props.intl.formatMessage({ id: "createBannerForm.button.add" })}
            height={48}
            disabled={props.isLoading}
          />
        </span>
      </div>
    </Form>
  );
};

interface IFormProps {
  intl: IntlShape;
  isLoading: boolean;
  onCancel: () => void;
  onSubmit: (values: ICreateBannerFormValues) => void;
}

export const CreateBannerFormComponent = withFormik<IFormProps, ICreateBannerFormValues>({
  mapPropsToValues: () => ({
    platform: 1,
    name: ""
  }),

  validate: (values: ICreateBannerFormValues, props) => {
    const errors: FormikErrors<ICreateBannerFormValues> = {};
    if (!values.name) {
      errors.name = props.intl.formatMessage({ id: "createBannerForm.error.name.required" });
    }

    return errors;
  },

  handleSubmit: (values, bag) => {
    bag.props.onSubmit(values);
  }
})(InnerForm);
