import { handleError } from "@app/util/handle-error";
import { ProductsApi, OutProductDTO, CreateProductDTO, InProductDTO } from "@app/api/generated";
import { getAuthorizedApiConfig } from "@app/util/api-config";
import { AppThunk } from "@app/redux/store";

import { productsActions } from "../reducers/products";

const getProducts =
  (skip: number, take: number, platformId?: number, searchQuery?: string, status?: number): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(productsActions.setIsLoading(true));

      const api = new ProductsApi(await getAuthorizedApiConfig());
      const isLive = !status ? undefined : status === 1 ? "true" : "false";
      const resultFromApi = await api.productsExtendedGet(take, skip, searchQuery, platformId, isLive);

      dispatch(productsActions.setProducts(resultFromApi.products || []));
      dispatch(productsActions.setTotalResults(resultFromApi.total));
      dispatch(productsActions.setTotalPages(Math.ceil(resultFromApi.total / take)));
      dispatch(productsActions.setIsLoading(false));
    } catch (error) {
      handleError(error, dispatch, "Failed to get products");
    }
  };

const getProduct =
  (id: number): AppThunk =>
  async (dispatch): Promise<OutProductDTO | undefined> => {
    try {
      const api = new ProductsApi(await getAuthorizedApiConfig());

      const resultFromApi = await api.productsIdGet(true, id);

      dispatch(productsActions.setSelectedProduct(resultFromApi));

      return resultFromApi;
    } catch (error) {
      handleError(error, dispatch, "Failed to get external deal filter");

      return undefined;
    }
  };

const deleteProduct =
  (id: number): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const api = new ProductsApi(await getAuthorizedApiConfig());

      const isDeleted = await api.productsIdDelete(id);

      if (isDeleted) {
        dispatch(productsActions.deleteProductFromList(id));
      }
    } catch (error) {
      handleError(error, dispatch, "Failed to delete product");
    }
  };

const createProduct =
  (product: CreateProductDTO): AppThunk =>
  async (dispatch): Promise<OutProductDTO | undefined> => {
    try {
      const api = new ProductsApi(await getAuthorizedApiConfig());
      const createdProduct = await api.productsPost(product);

      dispatch(productsActions.setSelectedProduct(createdProduct));

      return createdProduct;
    } catch (error) {
      handleError(error, dispatch, "Failed to create new product");
    }

    return undefined;
  };

const saveProduct =
  (product: InProductDTO): AppThunk =>
  async (dispatch): Promise<OutProductDTO | undefined> => {
    try {
      const api = new ProductsApi(await getAuthorizedApiConfig());
      const savedProduct = await api.productsIdPut(product.id, product);

      dispatch(productsActions.setSelectedProduct(savedProduct));

      return savedProduct;
    } catch (error: any) {
      const e = await error.json();
      let message = "Failed to save product";

      if (e?.message?.toLocaleLowerCase()?.includes("duplicate")) {
        message = "Slug already exists, choose another one";
      }

      handleError(error, dispatch, message);
    }
  };

export const productsThunk = {
  getProducts,
  getProduct,
  deleteProduct,
  createProduct,
  saveProduct
};
