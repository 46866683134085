import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { useParams, useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";

import { ClickableComponent } from "@app/core";
import BackIcon from "@assets/icons/rounded/arrow-back.svg";
import BinIcon from "@assets/icons/rounded/bin.svg";
import CopyIcon from "@assets/icons/rounded/copy.svg";
import { ModalComponent } from "@app/core/modal";
import { DeleteModal } from "@app/modules/delete-modal/delete-modal.component";
import { BasicModal } from "@app/modules/basic-modal";
import { CreateExternalDealFilterDTO, InExternalDealFilterDTO, OutExternalDealFilterDTO } from "@app/api/generated";
import { SpinningLoader } from "@app/core/spinning-loader/spinning-loader";
import { useAppDispatch, useAppSelector } from "@app/redux/store";
import { externalDealFiltersThunk } from "@app/redux/thunks/external-deal-filters.thunk";
import { ROUTES } from "@app/constants/routes";
import { externalDealFiltersActions } from "@app/redux/reducers/external-deal-filters";

import { ExternalDealFilterDetailForm } from "./form/external-deal-filter.form";
import { ExternalDealFilterDetailFormInfo } from "./form-info/external-deal-filter-form-info";
import styles from "./external-deal-filter-detail-component.module.scss";

const ExternalDealFilterDetail = () => {
  const { id } = useParams<{ id: string }>();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { selectedExternalDealFilter } = useAppSelector((state) => state.externalDealFilters);
  const history = useNavigate();

  const [isDuplicating, setIsDuplicating] = useState<boolean>(false);
  const [isDeleteExternalDealFilterOpen, setIsDeleteExternalDealFilterOpen] = useState<boolean>(false);
  const [isLeaveWarningOpen, setIsLeaveWarningOpen] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isExternalDealFilterEdited, setIsExternalDealFilterEdited] = useState<boolean>(false);
  const externalDealFilterEditStateForEventListeners = useRef(isExternalDealFilterEdited);

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("beforeunload", checkReload);

    return () => {
      window.removeEventListener("beforeunload", checkReload);
      dispatch(externalDealFiltersActions.setSelectedExternalDealFilter(undefined));
    };
  }, []);

  useEffect(() => {
    fetchDeal(id);
  }, [id]);

  const duplicateFilter = async () => {
    try {
      if (selectedExternalDealFilter) {
        setIsDuplicating(true);
        const result: OutExternalDealFilterDTO = (await dispatch(
          externalDealFiltersThunk.createExternalDealFilter({
            ...selectedExternalDealFilter,
            title: intl.formatMessage(
              {
                id: "externalDealFilters.detail.duplicate.newName"
              },
              { edfTitle: selectedExternalDealFilter.title }
            ),
            lastRunEndDate: undefined,
            nextRunAtDate: undefined,
            lastRunStartDate: undefined,
            live: false
          })
        )) as any;

        if (result) {
          toast.success(intl.formatMessage({ id: "externalDealFilters.detail.duplicate.notify.success" }));
          dispatch(externalDealFiltersActions.setSelectedExternalDealFilter());
          history(`${ROUTES.EXTERNAL_DEAL_FILTERS}/${result.id}`);
        }

        setIsDuplicating(false);
      }
    } catch {
      toast.success(intl.formatMessage({ id: "externalDealFilters.detail.duplicate.notify.error" }));
    }
  };

  const fetchDeal = async (dealId?: string) => {
    if (dealId !== "new") {
      const result: OutExternalDealFilterDTO | undefined = (await dispatch(
        externalDealFiltersThunk.getExternalDealFilter(Number(dealId))
      )) as any;

      if (!result) {
        history(ROUTES.EXTERNAL_DEAL_FILTERS);
      }
    }
  };

  useEffect(() => {
    externalDealFilterEditStateForEventListeners.current = isExternalDealFilterEdited;
  }, [isExternalDealFilterEdited]);

  const checkReload = (e: BeforeUnloadEvent) => {
    if (externalDealFilterEditStateForEventListeners.current) {
      e.preventDefault();
      e.returnValue = "";
    }
  };

  const onDelete = async () => {
    onCancel(false);
    if (selectedExternalDealFilter) {
      await dispatch(externalDealFiltersThunk.deleteExternalDealFilter(selectedExternalDealFilter.id));

      history(ROUTES.EXTERNAL_DEAL_FILTERS);
    }
    setIsLeaveWarningOpen(false);
  };

  const onCancel = (isSavedCheck: boolean) => {
    if (isExternalDealFilterEdited && isSavedCheck) {
      setIsLeaveWarningOpen(true);
    } else {
      setIsLeaveWarningOpen(false);
      cleanEditState();
      history(ROUTES.EXTERNAL_DEAL_FILTERS);
    }
  };

  const onSave = async (values: InExternalDealFilterDTO | CreateExternalDealFilterDTO) => {
    setIsSaving(true);
    let returnValue: OutExternalDealFilterDTO | undefined;

    if ("id" in values) {
      returnValue = (await dispatch(externalDealFiltersThunk.saveExternalDealFilter(values))) as any;
    } else {
      returnValue = (await dispatch(externalDealFiltersThunk.createExternalDealFilter(values))) as any;
    }

    if (returnValue) {
      toast.success(intl.formatMessage({ id: "externalDealFilters.detail.form.submit.notify.success" }));
      cleanEditState();

      if (id === "new") {
        history(`${ROUTES.EXTERNAL_DEAL_FILTERS}/${returnValue.id}`);
      }
    }
  };

  const onEdit = () => {
    if (!isExternalDealFilterEdited) {
      setIsExternalDealFilterEdited(true);
    }
  };

  const cleanEditState = () => {
    setIsExternalDealFilterEdited(false);
    setIsSaving(false);
  };

  return (
    <div className={styles.categoryDetail}>
      <div className={styles.topBar}>
        <div className={clsx(styles.container, styles.topBarContainer)}>
          <div className={styles.topBarLeft}>
            <ClickableComponent
              variant="link-primary"
              title={
                selectedExternalDealFilter
                  ? selectedExternalDealFilter?.title
                  : intl.formatMessage({
                      id: "externalDealFilters.detail.titleNew"
                    })
              }
              icon={BackIcon}
              iconPosition="left"
              iconSize="24px"
              onClick={() => onCancel(true)}
            />
          </div>
          {id !== "new" && (
            <div className={styles.topBarRight}>
              <ClickableComponent
                variant="primary-inverted"
                iconStyle="filled"
                title={intl.formatMessage({ id: "externalDealFilters.detail.duplicate" })}
                buttonType="button"
                icon={CopyIcon}
                iconPosition="left"
                iconSize="18px"
                height={40}
                onClick={duplicateFilter}
                disabled={isDuplicating}
              />
              <ClickableComponent
                variant="tertiary"
                iconFillColor="currentColor"
                title={intl.formatMessage({ id: "externalDealFilters.detail.delete" })}
                buttonType="button"
                icon={BinIcon}
                iconPosition="left"
                iconSize="20px"
                height={40}
                onClick={() => setIsDeleteExternalDealFilterOpen(true)}
              />
            </div>
          )}
        </div>
      </div>

      <div className={styles.container}>
        {!selectedExternalDealFilter && id !== "new" ? (
          <div className={styles.details}>
            <SpinningLoader />
          </div>
        ) : (
          <>
            <div className={styles.details} style={{ marginBottom: "24px" }}>
              <ExternalDealFilterDetailFormInfo externalDealFilter={selectedExternalDealFilter} />
            </div>
            <div className={styles.details}>
              <ExternalDealFilterDetailForm
                intl={intl}
                onSubmit={onSave}
                onCancel={onCancel}
                onEdit={onEdit}
                disableSaveButton={!isExternalDealFilterEdited}
                externalDealFilter={selectedExternalDealFilter}
                isSubmittingValues={isSaving}
              />
            </div>
          </>
        )}
      </div>

      <ModalComponent
        title={intl.formatMessage({ id: "externalDealFilters.detail.modal.delete.title" })}
        isModalOpen={isDeleteExternalDealFilterOpen}
        onCloseModal={() => {
          setIsDeleteExternalDealFilterOpen(false);
        }}
        variant="big"
      >
        <DeleteModal
          onCancel={() => {
            setIsDeleteExternalDealFilterOpen(false);
          }}
          onDelete={onDelete}
        />
      </ModalComponent>

      <ModalComponent
        title={intl.formatMessage({ id: "general.modal.leavePage.title" })}
        isModalOpen={isLeaveWarningOpen}
        onCloseModal={() => setIsLeaveWarningOpen(false)}
        variant="big"
      >
        <BasicModal
          onApprove={() => onCancel(false)}
          onCancel={() => setIsLeaveWarningOpen(false)}
          onApproveTitle={intl.formatMessage({ id: "general.modal.leavePage.approve" })}
          onCancelTitel={intl.formatMessage({ id: "general.modal.leavePage.cancel" })}
          description={intl.formatMessage({ id: "general.modal.leavePage.description" })}
        />
      </ModalComponent>
    </div>
  );
};

export { ExternalDealFilterDetail };
