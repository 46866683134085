import React from "react";
import clsx from "clsx";

import { IconComponent } from "@app/core";
import CheckmarkIcon from "@assets/icons/rounded/check.svg";

import styles from "./language-switcher.module.scss";

interface IProps {
  currentLocale: string;
  locale: string;
  setLocale: (locale: string) => void;
  value?: string;
}

export const LanguageSwitcherDropdownItem = (props: IProps) => {
  const changeLocale = () => {
    props.setLocale(props.locale);
  };

  return (
    <button
      id={props.locale}
      className={clsx(styles.dropdownItem, props.locale === props.currentLocale && styles.isActive)}
      type="button"
      onClick={changeLocale}
    >
      {props.locale === props.currentLocale && (
        <IconComponent icon={CheckmarkIcon} size="24px" fillColor="currentColor" />
      )}
      {props.value}
    </button>
  );
};
