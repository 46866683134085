import React from "react";
import { withFormik, FormikProps, FormikErrors, Form } from "formik";
import { IntlShape } from "react-intl";

import { TextFieldComponent, ClickableComponent } from "@app/core";
import { SelectComponent } from "@app/core/select";
import { platformType, platformTranslationKeys } from "@app/constants/platform";
import { IFilterType } from "@app/constants/filter";
import { CreateDealDTO, CreateDealDTOCreatedByEnum } from "@app/api/generated";
import { FilterItem } from "@app/api/core/filter/filter-item";
import { getMerchants } from "@app/modules/deal-detail/components/api-calls";
import { mapMerchantToFilterItem } from "@app/api/core/filter/map-filter-item";
import { useRefState } from "@app/util/use-ref-state";
import { FormRow } from "@webbio/components";
import { FormField } from "@app/core/form-field/form-field";
import { FormGroup } from "@app/core/form-group/form-group";
import { ReactSelect } from "@app/core/react-select/react-select";
import { getFilterItemFormErrors } from "@app/util/get-filter-item-form-errors";

import styles from "./create-deal-modal-component.module.scss";

export interface ICreateDealFormValues {
  name: string;
  platform: platformType;
  ean: string;
  merchant?: FilterItem;
}

interface IOtherProps {
  intl: IntlShape;
  isLoading: boolean;
  onCancel: () => void;
}

const InnerForm = (props: IOtherProps & FormikProps<ICreateDealFormValues>) => {
  const { touched, errors, setFieldValue } = props;
  const platformRef = useRefState(props.values.platform);

  const translatedPlatforms = platformTranslationKeys.map((untranslatedPlatform) => {
    return {
      id: untranslatedPlatform.id,
      name: props.intl.formatMessage({ id: untranslatedPlatform.name })
    } as IFilterType;
  });

  const onCustomChange = (value: any, id: string) => {
    handleChange(value);
    setFieldValue(id, value);
  };

  const handleChange = (value: any) => {
    props.handleChange(value);
  };

  const loadMerchants = async (inputValue: string, callback: (options: FilterItem[]) => void) => {
    const merchants = await getMerchants(inputValue, platformRef.current);
    callback(merchants ? merchants.map((merchant) => mapMerchantToFilterItem(merchant)) : []);
  };

  return (
    <Form>
      <div className={styles.form}>
        <FormGroup hideSeparator>
          <FormRow>
            <FormField>
              <TextFieldComponent
                label={{
                  label: props.intl.formatMessage({ id: "createDealForm.input.name.label" }),
                  errorMessage: touched.name === true && errors.name
                }}
                placeholder=""
                value={props.values.name}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                type="text"
                id="name"
                errorMessage={touched.name === true && errors.name}
              />
            </FormField>

            <FormField>
              <TextFieldComponent
                label={{
                  label: props.intl.formatMessage({ id: "createDealForm.input.ean.label" }),
                  tooltip: props.intl.formatMessage({ id: "createDealForm.input.ean.tooltip" }),
                  errorMessage: touched.ean === true && errors.ean
                }}
                placeholder=""
                value={props.values.ean}
                onChange={(e) => props.handleChange(e)}
                onBlur={props.handleBlur}
                type="text"
                id="ean"
                errorMessage={touched.ean === true && errors.ean}
              />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <ReactSelect
                id="merchant"
                label={{
                  label: props.intl.formatMessage({ id: "createDealForm.input.merchant.label" }),
                  tooltip: props.intl.formatMessage({ id: "createDealForm.input.merchant.tooltip" }),
                  errorMessage: getFilterItemFormErrors(touched.merchant, errors.merchant)
                }}
                isClearable
                value={props.values.merchant}
                loadOptions={loadMerchants}
                isAsync
                onChange={(e) => onCustomChange(e, "merchant")}
              />
            </FormField>
            <FormField>
              <SelectComponent
                height={40}
                onChange={props.handleChange}
                options={translatedPlatforms}
                id="platform"
                label={{
                  label: props.intl.formatMessage({ id: "createDealForm.input.platform.label" }),
                  tooltip: props.intl.formatMessage({ id: "createDealForm.input.platform.tooltip" })
                }}
              />
            </FormField>
          </FormRow>
        </FormGroup>
        <div className={styles.buttons}>
          <ClickableComponent
            buttonType="button"
            title={props.intl.formatMessage({ id: "createDealForm.button.cancel" })}
            onClick={() => {
              props.onCancel();
            }}
            height={48}
            variant="primary-inverted"
          />
          <ClickableComponent
            buttonType="submit"
            title={props.intl.formatMessage({ id: "createDealForm.button.add" })}
            height={48}
            disabled={props.isLoading}
          />
        </div>
      </div>
    </Form>
  );
};

interface IFormProps {
  intl: IntlShape;
  isLoading: boolean;
  onCancel: () => void;
  onSubmit: (values: CreateDealDTO) => void;
}

export const CreateDealFormComponent = withFormik<IFormProps, ICreateDealFormValues>({
  mapPropsToValues: () => ({
    platform: 1,
    name: "",
    merchant: undefined,
    ean: ""
  }),

  validate: (values: ICreateDealFormValues, props) => {
    const errors: FormikErrors<ICreateDealFormValues> = {};
    if (!values.name) {
      errors.name = props.intl.formatMessage({ id: "createDealForm.error.name.required" });
    }

    if (!values.platform) {
      errors.platform = props.intl.formatMessage({ id: "createDealForm.error.platform.required" });
    }

    if (!values.merchant || !values.merchant?.value) {
      errors.merchant = props.intl.formatMessage({ id: "createDealForm.error.merchant.required" });
    }

    if (!values.ean) {
      errors.ean = props.intl.formatMessage({ id: "createDealForm.error.ean.required" });
    }

    return errors;
  },

  handleSubmit: (values, bag) => {
    const deal = mapValuesToDeal(values);
    bag.props.onSubmit(deal);
  }
})(InnerForm);

const mapValuesToDeal = (values: ICreateDealFormValues) => {
  const newDeal: CreateDealDTO = {
    platform: { id: Number(values.platform), name: "", googleExperimentId: "", wctPlatformId: 0 },
    ean: values.ean,
    merchant: values.merchant && { id: Number(values.merchant.value) },
    name: values.name,
    live: false,
    createdBy: CreateDealDTOCreatedByEnum.DASHBOARD
  };

  return newDeal;
};
