import * as React from "react";
import { withFormik, FormikProps, FormikErrors, Form } from "formik";
import { IntlShape } from "react-intl";

import { TextFieldComponent, ClickableComponent } from "@app/core";
import { SelectComponent } from "@app/core/select";
import { userRoles, UserRoleType } from "@app/api/core/users/user";
import { ReactSelect } from "@app/core/react-select/react-select";
import { InUserDTO, OutDetailUserDTO } from "@app/api/generated";
import { mapMerchantToFilterItem } from "@app/api/core/filter/map-filter-item";
import { FilterItem } from "@app/api/core/filter/filter-item";
import { getMerchants } from "@app/modules/deal-detail/components/api-calls";
import { validateEmail } from "@app/util";

import styles from "./user-modal-component.module.scss";

export interface IUserFormValues {
  company: string;
  email: string;
  firstName: string;
  id?: number;
  lastName: string;
  merchants: FilterItem[];
  phone: string;
  role: any;
}

interface IOtherProps {
  intl: IntlShape;
  isLoading: boolean;
  onCancel: () => void;
  user?: OutDetailUserDTO;
}

const InnerForm = (props: IOtherProps & FormikProps<IUserFormValues>) => {
  const { touched, errors } = props;

  const onCustomChange = (value: any, id: string) => {
    props.handleChange(value);
    props.setFieldValue(id, value);
  };

  const loadMerchants = async (inputValue: string, callback: (options: FilterItem[]) => void) => {
    const merchants = await getMerchants(inputValue);
    callback(merchants ? merchants.map((merchant) => mapMerchantToFilterItem(merchant, true)) : []);
  };

  const translatedRoles = userRoles.map((untranslatedUserStatus) => {
    return {
      id: untranslatedUserStatus.id,
      name: props.intl.formatMessage({ id: untranslatedUserStatus.name })
    } as UserRoleType;
  });

  return (
    <Form>
      <div className={styles.form}>
        <div className={styles.fieldsContainer}>
          <div className={styles.fields}>
            <div className={styles.formField}>
              <SelectComponent
                height={40}
                value={props.values.role}
                onChange={props.handleChange}
                options={translatedRoles}
                id="role"
                label={{
                  label: props.intl.formatMessage({ id: "userDetailForm.input.role.label" }),
                  tooltip: props.intl.formatMessage({ id: "userDetailForm.input.role.tooltip" })
                }}
              />
            </div>
          </div>

          <div className={styles.fields}>
            <div className={styles.formField}>
              <ReactSelect
                id="merchants"
                label={{
                  label: props.intl.formatMessage({ id: "userDetailForm.input.merchants.label" }),
                  tooltip: props.intl.formatMessage({ id: "userDetailForm.input.merchants.tooltip" })
                }}
                isMulti
                enableCollapsing
                onBlur={props.handleBlur}
                isClearable
                value={props.values.merchants}
                loadOptions={loadMerchants}
                isAsync
                onChange={(e) => onCustomChange(e, "merchants")}
              />
            </div>
          </div>

          <div className={styles.fields}>
            <div className={styles.formField}>
              <TextFieldComponent
                height={40}
                value={props.values.company}
                onChange={props.handleChange}
                errorMessage={touched.company && errors.company}
                id="company"
                label={{
                  label: props.intl.formatMessage({ id: "userDetailForm.input.company.label" }),
                  tooltip: props.intl.formatMessage({ id: "userDetailForm.input.company.tooltip" })
                }}
              />
            </div>
          </div>

          <div className={styles.fields}>
            <div className={styles.formField}>
              <TextFieldComponent
                height={40}
                value={props.values.firstName}
                errorMessage={touched.firstName && errors.firstName}
                onChange={props.handleChange}
                id="firstName"
                label={{
                  label: props.intl.formatMessage({ id: "userDetailForm.input.firstName.label" }),
                  tooltip: props.intl.formatMessage({ id: "userDetailForm.input.firstName.tooltip" })
                }}
              />
            </div>
            <div className={styles.formField}>
              <TextFieldComponent
                height={40}
                value={props.values.lastName}
                errorMessage={touched.lastName && errors.lastName}
                onChange={props.handleChange}
                id="lastName"
                label={{
                  label: props.intl.formatMessage({ id: "userDetailForm.input.lastName.label" }),
                  tooltip: props.intl.formatMessage({ id: "userDetailForm.input.lastName.tooltip" })
                }}
              />
            </div>
          </div>
          <div className={styles.fields}>
            <div className={styles.formField}>
              <TextFieldComponent
                height={40}
                value={props.values.email}
                errorMessage={touched.email && errors.email}
                onChange={props.handleChange}
                id="email"
                label={{
                  label: props.intl.formatMessage({ id: "userDetailForm.input.email.label" }),
                  tooltip: props.intl.formatMessage({ id: "userDetailForm.input.email.tooltip" })
                }}
              />
            </div>
            <div className={styles.formField}>
              <TextFieldComponent
                height={40}
                value={props.values.phone}
                onChange={props.handleChange}
                id="phone"
                label={{
                  label: props.intl.formatMessage({ id: "userDetailForm.input.phone.label" }),
                  tooltip: props.intl.formatMessage({ id: "userDetailForm.input.phone.tooltip" })
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.buttons}>
        <span className={styles.firstButton}>
          <ClickableComponent
            buttonType="button"
            title={props.intl.formatMessage({ id: "userDetailForm.cancel" })}
            onClick={() => {
              props.onCancel();
            }}
            height={48}
            variant="primary-inverted"
          />
        </span>
        <span>
          <ClickableComponent
            buttonType="submit"
            title={
              props.user
                ? props.intl.formatMessage({ id: "userDetailForm.save" })
                : props.intl.formatMessage({ id: "userDetailForm.add" })
            }
            height={48}
            disabled={props.isLoading}
          />
        </span>
      </div>
    </Form>
  );
};

interface IFormProps {
  intl: IntlShape;
  isLoading: boolean;
  onCancel: () => void;
  onSubmit: (values: InUserDTO) => void;
  user?: OutDetailUserDTO;
}

export const UserFormComponent = withFormik<IFormProps, IUserFormValues>({
  mapPropsToValues: (props: IFormProps) => ({
    company: props.user?.company || "",
    email: props.user?.email || "",
    firstName: props.user?.firstName || "",
    lastName: props.user?.lastName || "",
    role: props.user?.role?.id || 2,
    phone: props.user?.phone || "",
    merchants: props.user?.merchants?.map((merchant) => mapMerchantToFilterItem(merchant, true)) || [],
    id: props.user?.id
  }),

  validate: (values: IUserFormValues, props) => {
    const errors: FormikErrors<IUserFormValues> = {};

    if (!values.email) {
      errors.email = props.intl.formatMessage({ id: "userDetailForm.error.email.required" });
    } else if (!validateEmail(values.email)) {
      errors.email = props.intl.formatMessage({ id: "userDetailForm.error.email.invalid" });
    }

    if (!values.company) {
      errors.company = props.intl.formatMessage({ id: "userDetailForm.error.company.required" });
    }

    if (!values.firstName) {
      errors.firstName = props.intl.formatMessage({ id: "userDetailForm.error.fistname.required" });
    }

    if (!values.lastName) {
      errors.lastName = props.intl.formatMessage({ id: "userDetailForm.error.lastname.required" });
    }

    return errors;
  },

  handleSubmit: (values, bag) => {
    const user = mapValuesToUser(values);
    bag.props.onSubmit(user);
  }
})(InnerForm);

const mapValuesToUser = (values: IUserFormValues) => {
  const newUser: InUserDTO = {
    email: values.email,
    merchants: values.merchants.map((filterItem) => ({
      id: Number(filterItem.value)
    })),
    role: { id: Number(values.role), name: "role" },
    company: values.company,
    firstName: values.firstName,
    lastName: values.lastName,
    phone: values.phone,
    id: values.id
  };

  return newUser;
};
