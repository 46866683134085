import React, { useState } from "react";
import { withFormik, FormikProps, Form } from "formik";
import { IntlShape } from "react-intl";

import { TextFieldComponent, ClickableComponent, ResourceTextComponent } from "@app/core";
import { passwordFormValidation, mailFormValidation } from "@app/util/form-validation-messages";

import styles from "./change-email-form.module.scss";

interface IFormValues {
  currentPassword: string;
  newEmail: string;
}

interface IOtherProps {
  currentEmail: string;
  intl: IntlShape;
}

const InnerForm = (props: IOtherProps & FormikProps<IFormValues>) => {
  const { touched, errors } = props;

  const [formIsTouched, setFormIsTouched] = useState(false);

  const handleChange = (values: any) => {
    props.handleChange(values);

    if (!formIsTouched) {
      setFormIsTouched(true);
    }
  };

  return (
    <Form>
      <div className={styles.dataBlock}>
        <div className={styles.label}>
          <span>
            <ResourceTextComponent resourceKey="userAccountDetail.loginDetails.changeEmail.title" />
          </span>
          <span className={styles.description}>
            <ResourceTextComponent
              resourceKey="userAccountDetail.loginDetails.changeEmail.description"
              values={{ currentEmail: props.currentEmail }}
            />
          </span>
        </div>

        <div className={styles.fieldsContainer}>
          <div className={styles.fields}>
            <div className={styles.formField}>
              <TextFieldComponent
                height={40}
                value={props.values.newEmail}
                onChange={handleChange}
                id="newEmail"
                errorMessage={touched.newEmail && errors.newEmail}
                label={{
                  label: props.intl.formatMessage({
                    id: "userAccountDetail.loginDetails.changeEmail.input.newEmail.label"
                  }),
                  tooltip: props.intl.formatMessage({
                    id: "userAccountDetail.loginDetails.changeEmail.input.newEmail.tooltip"
                  })
                }}
              />
            </div>
            <div className={styles.formField}>
              <TextFieldComponent
                height={40}
                value={props.values.currentPassword}
                onChange={handleChange}
                id="currentPassword"
                type="password"
                errorMessage={touched.currentPassword && errors.currentPassword}
                label={{
                  label: props.intl.formatMessage({
                    id: "userAccountDetail.loginDetails.changeEmail.input.currentPassword.label"
                  }),
                  tooltip: props.intl.formatMessage({
                    id: "userAccountDetail.loginDetails.changeEmail.input.currentPassword.tooltip"
                  })
                }}
              />
            </div>
          </div>
          <div className={styles.fields}>
            <div className={styles.formField}>
              <div className={styles.buttonSubmit}>
                <ClickableComponent
                  buttonType="submit"
                  title={props.intl.formatMessage({
                    id: "userAccountDetail.loginDetails.changeEmail.submit"
                  })}
                  disabled={!formIsTouched}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

interface IMyFormProps {
  currentEmail: string;
  intl: IntlShape;
  onSubmit: (values: any) => void;
}

export const ChangeEmailForm = withFormik<IMyFormProps, IFormValues>({
  mapPropsToValues: () => ({
    newEmail: "",
    currentPassword: ""
  }),

  validate: (values: IFormValues, props: IMyFormProps) => {
    const errors: any = {};

    const mailValidation = mailFormValidation(values.newEmail, true);
    const passwordValidation = passwordFormValidation(values.currentPassword, true);

    if (mailValidation) {
      errors.newEmail = props.intl.formatMessage({
        id: mailValidation
      });
    }
    if (passwordValidation) {
      errors.currentPassword = props.intl.formatMessage({
        id: passwordValidation
      });
    }

    return errors;
  },

  handleSubmit: (values, bag) => {
    bag.props.onSubmit(values);
    bag.resetForm();
  }
})(InnerForm);
