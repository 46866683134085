import React from "react";
import clsx from "clsx";
import { useIntl } from "react-intl";

import styles from "./tab-component.module.scss";

export interface ITabProps {
  onSelect: (selectedTab: string) => void;
  selectedTab: string;
  tabs: string[];
}

export const Tab = (props: ITabProps) => {
  const intl = useIntl();

  return (
    <div className={styles.tabComponent}>
      <div className={styles.tabs}>
        {props.tabs.map((tab) => (
          <button
            key={tab}
            type="button"
            onClick={() => props.onSelect(tab)}
            className={clsx(styles.tabItem, props.selectedTab === tab && styles.isSelected)}
          >
            {intl.formatMessage({ id: tab })}
          </button>
        ))}
      </div>
    </div>
  );
};
