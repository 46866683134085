import { merge, throttle } from "lodash";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import { configureStore, ThunkAction, AnyAction, combineReducers } from "@reduxjs/toolkit";

import { loadStateFromLocalStorage, saveStateToLocalStorage } from "./local-storage";
import authorizationReducer from "./reducers/authorization";
import bannersReducer from "./reducers/banners";
import dealsReducer from "./reducers/deals";
import filtersReducer from "./reducers/filters";
import localesReducer, { ILocalesState } from "./reducers/locales";
import merchantsReducer from "./reducers/merchants";
import platformReducer from "./reducers/platform";
import settingsReducer from "./reducers/settings";
import userAccountReducer from "./reducers/user-account";
import usersReducer from "./reducers/users";
import featuredCategoriesReducer from "./reducers/featured-categories";
import externalDealFiltersReducer from "./reducers/external-deal-filters";
import productsReducer from "./reducers/products";

const allReducers = combineReducers({
  locales: localesReducer,
  authorization: authorizationReducer,
  banners: bannersReducer,
  filters: filtersReducer,
  deals: dealsReducer,
  merchants: merchantsReducer,
  platform: platformReducer,
  settings: settingsReducer,
  userAccount: userAccountReducer,
  users: usersReducer,
  featuredCategories: featuredCategoriesReducer,
  externalDealFilters: externalDealFiltersReducer,
  products: productsReducer
});

const rootReducer = (state: ReturnType<typeof allReducers> | undefined, action: AnyAction): RootState => {
  const persistedState = loadStateFromLocalStorage();

  if (action.type === "settings/resetStore") {
    return allReducers(undefined, action);
  }

  return allReducers(merge(persistedState, state), action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

export const configureAppStore = (preloadedState: RootState): typeof store => {
  const storeWithPreloadedState = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false
      }),
    preloadedState
  });

  subscribeToStore(storeWithPreloadedState);

  return storeWithPreloadedState;
};

const subscribeToStore = (newStore: typeof store): void => {
  newStore.subscribe(
    throttle(() => {
      const state = newStore.getState();

      return saveStateToLocalStorage({
        locales: {
          locale: state.locales.locale
        } as ILocalesState
      });
    }, 500)
  );
};

export type RootState = ReturnType<typeof allReducers>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;
export type AppDispatch = typeof store.dispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();
