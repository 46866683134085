import React, { useEffect } from "react";
import { withFormik, FormikProps, Form } from "formik";
import { IntlShape } from "react-intl";

import { TextFieldComponent } from "@app/core";
import { SelectComponent } from "@app/core/select";
import { ImageUpload } from "@app/core/image-upload/image-upload.component";
import { OutDealDTO, ImageDTO, InDealDTO } from "@app/api/generated";
import { storeStatusType } from "@app/api/core/merchant/merchant";
import { NumberInput } from "@app/core/number-input/number-input.component";
import { platformType, getTranslatedPlatforms } from "@app/constants/platform";
import { FilterItem } from "@app/api/core/filter/filter-item";
import {
  mapToFilterItem,
  mapMerchantToFilterItem,
  mapExternalDealFilterToFilterItem
} from "@app/api/core/filter/map-filter-item";
import { getTranslatedStatusses } from "@app/constants/status";
import { useRefState } from "@app/util/use-ref-state";
import { FormGroup } from "@app/core/form-group/form-group";
import { FormRow } from "@app/core/form-row/form-row";
import { FormField } from "@app/core/form-field/form-field";
import { FormColumn } from "@app/core/form-column/form-column";
import { getFilterItemFormErrors } from "@app/util/get-filter-item-form-errors";
import { ReactSelect } from "@app/core/react-select/react-select";
import { FormActions } from "@app/components/form-actions/form-actions";

import { getMerchants, getFilters, getExternalDealFilters } from "./api-calls";
import styles from "./deal-form.module.scss";

interface IFormValues {
  beforePrice?: number;
  brands?: FilterItem[];
  categories?: FilterItem[];
  externalDealFilters?: FilterItem[];
  currency?: string;
  ean?: string;
  featuredFilter?: FilterItem | null;
  id: number;
  image?: ImageDTO;
  merchant?: FilterItem | null;
  name: string;
  platform: platformType;
  price?: number;
  score?: number;
  scoreModifier?: number;
  sku?: string;
  sponsoredPosition?: number;
  status: storeStatusType;
  stock?: number;
  url: string;
  usp?: string;
}

const InnerForm = (props: IMyFormProps & FormikProps<IFormValues>) => {
  const { values, touched, errors } = props;
  const platformRef = useRefState(values.platform);
  const merchantRef = useRefState(values.merchant);
  const translatedPlatforms = getTranslatedPlatforms(props.intl);
  const translatedStoreStatus = getTranslatedStatusses(props.intl);

  useEffect(() => {
    if (props.initialValues.platform !== props.values.platform) {
      props.setFieldValue("externalDealFilters", null);
      props.setFieldValue("merchant", null);
    }
  }, [props.values.platform]);

  useEffect(() => {
    if (props.initialValues.merchant !== props.values.merchant) {
      props.setFieldValue("externalDealFilters", null);
    }
  }, [props.values.merchant]);

  const onCustomChange = (value: any, id: string) => {
    handleChange(value);
    props.setFieldValue(id, value);
  };

  const loadExternalDealFilters = async (inputValue: string, callback: (options: FilterItem[]) => void) => {
    const externalDealFilters = await getExternalDealFilters(
      0,
      50,
      merchantRef.current,
      platformRef.current,
      inputValue,
      true
    );

    callback(
      externalDealFilters
        ? externalDealFilters.map((externalDealFilter) => mapExternalDealFilterToFilterItem(externalDealFilter))
        : []
    );
  };

  const handleChange = (value: any) => {
    props.handleChange(value);
    props.onEdit();
  };

  const loadMerchants = async (inputValue: string, callback: (options: FilterItem[]) => void) => {
    const merchants = await getMerchants(inputValue, props.values.platform);
    callback(merchants ? merchants.map((merchant) => mapMerchantToFilterItem(merchant, false)) : []);
  };

  const loadBrands = async (inputValue: string, callback: (options: FilterItem[]) => void) => {
    const brands = await getFilters(inputValue, "3");

    callback(brands ? brands.map(mapToFilterItem) : []);
  };

  const loadCategories = async (inputValue: string, callback: (options: FilterItem[]) => void) => {
    const categories = await getFilters(inputValue, "1");
    callback(categories ? categories.map(mapToFilterItem) : []);
  };

  const loadLabels = async (inputValue: string, callback: (options: FilterItem[]) => void) => {
    const labels = await getFilters(inputValue, "2");
    callback(labels ? labels.map(mapToFilterItem) : []);
  };

  return (
    <Form>
      <div className={styles.form}>
        <FormGroup title={props.intl.formatMessage({ id: "dealForm.dataBlock.productFeatures" })}>
          <FormRow>
            <FormField>
              <TextFieldComponent
                height={40}
                value={props.values.id}
                onChange={handleChange}
                id="id"
                label={{
                  label: props.intl.formatMessage({ id: "dealForm.input.id.label" }),
                  tooltip: props.intl.formatMessage({ id: "dealForm.input.id.tooltip" })
                }}
                disabled
              />
            </FormField>
            <FormField>
              <ReactSelect
                id="featuredFilter"
                label={{
                  label: props.intl.formatMessage({ id: "dealForm.input.featuredFilter.label" }),

                  isOptionalField: true
                }}
                onBlur={props.handleBlur}
                isClearable
                value={props.values.featuredFilter}
                loadOptions={loadLabels}
                isAsync
                onChange={(e) => onCustomChange(e, "featuredFilter")}
              />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <NumberInput
                value={props.values.scoreModifier}
                onChange={onCustomChange}
                id="scoreModifier"
                type="decimal"
                label={{
                  label: props.intl.formatMessage({ id: "dealForm.input.scoreModifier.label" }),
                  isOptionalField: true
                }}
              />
            </FormField>
            <FormField>
              <NumberInput
                value={props.values.score}
                onChange={onCustomChange}
                id="score"
                type="decimal"
                label={{
                  label: props.intl.formatMessage({ id: "dealForm.input.score.label" }),
                  isOptionalField: true
                }}
              />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <TextFieldComponent
                id="ean"
                height={40}
                value={props.values.ean}
                onChange={handleChange}
                onBlur={props.handleBlur}
                label={{
                  label: props.intl.formatMessage({ id: "dealForm.input.ean.label" }),
                  errorMessage: touched.ean === true && errors.ean
                }}
              />
            </FormField>
            <FormField>
              <TextFieldComponent
                height={40}
                value={props.values.sku}
                onChange={handleChange}
                id="sku"
                label={{
                  label: props.intl.formatMessage({ id: "dealForm.input.sku.label" })
                }}
              />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <SelectComponent
                height={40}
                value={props.values.status}
                onChange={handleChange}
                options={translatedStoreStatus}
                id="status"
                label={{
                  label: props.intl.formatMessage({ id: "dealForm.input.status.label" })
                }}
              />
            </FormField>
            <FormField>
              <SelectComponent
                id="platform"
                height={40}
                value={props.values.platform}
                onChange={handleChange}
                onBlur={props.handleBlur}
                options={translatedPlatforms}
                label={{
                  label: props.intl.formatMessage({ id: "dealForm.input.platform.label" }),
                  errorMessage: touched.platform === true && errors.platform
                }}
              />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <TextFieldComponent
                id="name"
                height={40}
                value={props.values.name}
                onChange={handleChange}
                onBlur={props.handleBlur}
                label={{
                  label: props.intl.formatMessage({ id: "dealForm.input.name.label" }),
                  errorMessage: touched.name === true && errors.name
                }}
              />
            </FormField>
            <FormField>
              <TextFieldComponent
                height={40}
                value={props.values.url}
                onChange={handleChange}
                id="url"
                label={{
                  label: props.intl.formatMessage({ id: "dealForm.input.url.label" })
                }}
              />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <ImageUpload
                id="image"
                label={{
                  label: props.intl.formatMessage({ id: "dealForm.input.headerImage.label" })
                }}
                fullWidth
                onChange={onCustomChange}
                image={props.values.image}
              />
            </FormField>
            <FormColumn>
              <FormField>
                <TextFieldComponent
                  label={{
                    label: props.intl.formatMessage({ id: "dealForm.input.headerImage.title.label" })
                  }}
                  placeholder=""
                  value={props.values.image && props.values.image.title}
                  onChange={handleChange}
                  onBlur={props.handleBlur}
                  type="text"
                  id="image.title"
                  disabled={!props.values.image}
                />
              </FormField>
              <FormField>
                <TextFieldComponent
                  label={{
                    label: props.intl.formatMessage({ id: "dealForm.input.headerImage.alt.label" })
                  }}
                  placeholder=""
                  value={props.values.image && props.values.image.alt}
                  onChange={handleChange}
                  onBlur={props.handleBlur}
                  type="text"
                  id="image.alt"
                  disabled={!props.values.image}
                />
              </FormField>
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormField>
              <TextFieldComponent
                height={40}
                value={props.values.currency}
                onChange={handleChange}
                id="currency"
                label={{
                  label: props.intl.formatMessage({ id: "dealForm.input.currency.label" })
                }}
              />
            </FormField>
            <FormField>
              <NumberInput
                type="decimal"
                value={props.values.stock}
                onChange={onCustomChange}
                id="stock"
                label={{
                  label: props.intl.formatMessage({ id: "dealForm.input.stock.label" }),

                  isOptionalField: true
                }}
              />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <NumberInput
                value={props.values.price}
                onChange={onCustomChange}
                type="decimal"
                id="price"
                label={{
                  label: props.intl.formatMessage({ id: "dealForm.input.price.label" }),

                  isOptionalField: true
                }}
              />
            </FormField>
            <FormField>
              <NumberInput
                value={props.values.beforePrice}
                onChange={onCustomChange}
                id="beforePrice"
                type="decimal"
                label={{
                  label: props.intl.formatMessage({ id: "dealForm.input.priceBefore.label" }),

                  isOptionalField: true
                }}
              />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <ReactSelect
                id="merchant"
                label={{
                  label: props.intl.formatMessage({ id: "dealForm.input.store.label" }),
                  errorMessage: getFilterItemFormErrors(touched.merchant, errors.merchant)
                }}
                onBlur={props.handleBlur}
                isClearable
                value={props.values.merchant}
                loadOptions={loadMerchants}
                isAsync
                onChange={(e) => onCustomChange(e, "merchant")}
              />
            </FormField>
            <FormField>
              <ReactSelect
                id="brands"
                label={{
                  label: props.intl.formatMessage({ id: "dealForm.input.brands.label" }),

                  isOptionalField: true
                }}
                onBlur={props.handleBlur}
                isMulti
                isClearable
                value={props.values.brands}
                loadOptions={loadBrands}
                isAsync
                onChange={(e) => onCustomChange(e, `brands`)}
                enableCollapsing
              />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <ReactSelect
                id="categories"
                label={{
                  label: props.intl.formatMessage({ id: "dealForm.input.categories.label" }),
                  isOptionalField: true
                }}
                onBlur={props.handleBlur}
                isMulti
                isClearable
                value={props.values.categories}
                loadOptions={loadCategories}
                isAsync
                onChange={(e) => onCustomChange(e, `categories`)}
                enableCollapsing
              />
            </FormField>
            <FormField>
              <NumberInput
                value={props.values.sponsoredPosition || undefined}
                onChange={onCustomChange}
                id="sponsoredPosition"
                type="number"
                label={{
                  label: props.intl.formatMessage({ id: "dealForm.input.sponsoredPosition.label" }),
                  isOptionalField: true
                }}
              />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <ReactSelect
                id="externalDealFilters"
                label={{
                  label: props.intl.formatMessage({ id: "dealForm.input.externalDealFilters.label" }),
                  isOptionalField: true
                }}
                onBlur={props.handleBlur}
                isMulti
                isClearable
                value={props.values.externalDealFilters}
                loadOptions={loadExternalDealFilters}
                isAsync
                onChange={(e) => onCustomChange(e, "externalDealFilters")}
                enableCollapsing
              />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <TextFieldComponent
                id="usp"
                height={40}
                value={props.values.usp}
                onChange={handleChange}
                onBlur={props.handleBlur}
                label={{
                  label: props.intl.formatMessage({ id: "dealForm.input.usp.label" }),
                  tooltip: props.intl.formatMessage({ id: "dealForm.input.usp.tooltip" }),
                  errorMessage: touched.usp === true && errors.usp
                }}
              />
            </FormField>
          </FormRow>
        </FormGroup>

        <FormActions
          disableSaveButton={props.disableSaveButton}
          isSubmittingValues={props.isLoading}
          onCancel={() => props.onCancel(false)}
          onSubmit={props.handleSubmit}
        />
      </div>
    </Form>
  );
};

interface IMyFormProps {
  deal: OutDealDTO;
  disableSaveButton: boolean;
  intl: IntlShape;
  onCancel: (isSavedCheck: boolean) => void;
  onEdit: () => void;
  onSubmit: (values: InDealDTO) => void;
  isLoading?: boolean;
}

export const DealForm = withFormik<IMyFormProps, IFormValues>({
  mapPropsToValues: (props) => ({
    beforePrice: props.deal.beforePrice,
    status: props.deal.live ? storeStatusType.live : storeStatusType.offline,
    brands: props.deal.brands ? props.deal.brands.map(mapToFilterItem) : [],
    categories: props.deal.categories ? props.deal.categories.map(mapToFilterItem) : [],
    externalDealFilters: props.deal.externalDealFilters
      ? props.deal.externalDealFilters.map((externalDealFilter) =>
          mapExternalDealFilterToFilterItem(externalDealFilter)
        )
      : [],
    currency: props.deal.currency,
    ean: props.deal.ean,
    id: props.deal.id,
    image: props.deal.image,
    featuredFilter: props.deal.featuredFilter ? mapToFilterItem(props.deal.featuredFilter) : null,
    merchant: props.deal.merchant ? mapMerchantToFilterItem(props.deal.merchant, false) : null,
    name: props.deal.name,
    platform: props.deal.platform.id,
    price: props.deal.price,
    sku: props.deal.sku,
    stock: props.deal.stock,
    url: props.deal.url || "",
    score: props.deal.score,
    scoreModifier: props.deal.scoreModifier,
    sponsoredPosition: props.deal.sponsoredPosition || undefined,
    usp: props.deal.usp
  }),

  validate: (values: IFormValues, props) => {
    const errors: any = {};

    if (!values.ean) {
      errors.ean = props.intl.formatMessage({ id: "general.form.error.required" });
    }

    if (!values.platform) {
      errors.platform = props.intl.formatMessage({ id: "general.form.error.required" });
    }

    if (!values.name) {
      errors.name = props.intl.formatMessage({ id: "general.form.error.required" });
    }

    if (!values.merchant || !values.merchant?.value) {
      errors.merchant = props.intl.formatMessage({ id: "general.form.error.required" });
    }

    return errors;
  },

  handleSubmit: (values, bag) => {
    const deal = mapValuesToDeal(values);
    bag.props.onSubmit(deal);
  }
})(InnerForm);

const mapValuesToDeal = (values: IFormValues) => {
  const newDeal: InDealDTO = {
    beforePrice: values.beforePrice,
    brands: values.brands
      ? values.brands.map((item) => ({
          id: item.value
        }))
      : [],
    categories: values.categories
      ? values.categories.map((item) => ({
          id: item.value
        }))
      : [],
    externalDealFilters: values.externalDealFilters
      ? values.externalDealFilters.map((item) => ({
          id: Number(item.value)
        }))
      : [],
    currency: values.currency,
    ean: values.ean,
    id: values.id,
    image: values.image,
    featuredFilter: values.featuredFilter ? { id: Number(values.featuredFilter.value) } : (null as any), // Filter must be set to null to have it removed.
    merchant: values.merchant ? { id: Number(values.merchant.value) } : undefined,
    score: values.score,
    scoreModifier: values.scoreModifier,
    name: values.name,
    platform: { id: Number(values.platform), name: "", googleExperimentId: "", wctPlatformId: 0 },
    price: values.price,
    sku: values.sku,
    stock: values.stock,
    url: values.url,
    // I know this is ugly, sue me.
    // eslint-disable-next-line eqeqeq
    live: values.status == storeStatusType.live,
    sponsoredPosition: values.sponsoredPosition,
    usp: values.usp
  };

  return newDeal;
};
