import React, { useState, useEffect } from "react";
import { useDebounce } from "@app/util/use-debounce";

import Plus from "@assets/icons/rounded/add.svg";
import Search from "@assets/icons/rounded/search.svg";

import { ClickableComponent, TextFieldComponent } from "@app/core";
import { ModalComponent } from "@app/core/modal";
import { SelectComponent } from "@app/core/select";
import { storeStatus, editStatus, storeStatusType2 } from "@app/api/core/merchant/merchant";
import { useIntl } from "react-intl";
import { platformTranslationKeys } from "@app/constants/platform";
import { IFilterType } from "@app/constants/filter";
import { useAppDispatch, useAppSelector } from "@app/redux/store";
import {
  setCurrentEditStatus,
  setCurrentPlatform,
  setCurrentSearch,
  setCurrentStatus
} from "@app/redux/reducers/merchants";

import { CreateMerchantModal } from "../create-merchant-modal";
import styles from "./merchant-filter-bar-component.module.scss";

const MerchantFilterBar = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { currentEditStatus, currentPlatform, currentStatus } = useAppSelector((state) => state.merchants);
  const { role } = useAppSelector((state) => state.userAccount);

  const [search, setSearch] = useState<string>("");
  const debouncedSearchTerm = useDebounce(search, 500);
  const [isAddMerchantOpen, setIsAddMerchantOpen] = useState<boolean>(false);

  const translatedPlatforms = platformTranslationKeys.map((untranslatedPlatform) => {
    return {
      id: untranslatedPlatform.id,
      name: intl.formatMessage({ id: untranslatedPlatform.name })
    } as IFilterType;
  });

  const translatedStoreStatus = storeStatus.map((untranslatedStoreStatus) => {
    return {
      id: untranslatedStoreStatus.id,
      name: intl.formatMessage({ id: untranslatedStoreStatus.name })
    } as storeStatusType2;
  });

  const translatedEditStatus = editStatus.map((untranslatedEditStatus) => {
    return {
      id: untranslatedEditStatus.id,
      name: intl.formatMessage({ id: untranslatedEditStatus.name })
    } as storeStatusType2;
  });

  useEffect(() => {
    dispatch(setCurrentSearch(debouncedSearchTerm || undefined));
  }, [debouncedSearchTerm]);

  const onStatusChange = (e: React.ChangeEvent<any>) => {
    const value = Number(e.target.value);
    dispatch(setCurrentStatus(value || undefined));
  };

  const onEditChange = (e: React.ChangeEvent<any>) => {
    const value = Number(e.target.value);
    dispatch(setCurrentEditStatus(value || undefined));
  };

  const onPlatformChange = (e: React.ChangeEvent<any>) => {
    const value = Number(e.target.value);
    dispatch(setCurrentPlatform(value || undefined));
  };

  return (
    <div className={styles.storeFilterBar}>
      <div className={styles.searchField}>
        <TextFieldComponent
          height={40}
          placeholder={intl.formatMessage({ id: "storeFilterBar.input.search.placeholder" })}
          value={search}
          onChange={(e: React.ChangeEvent<any>) => {
            setSearch(e.target.value);
          }}
          type="text"
          id="search"
          icon={Search}
          width={318}
        />
      </div>

      <SelectComponent
        onChange={onStatusChange}
        value={currentStatus}
        options={[
          { id: 0, name: intl.formatMessage({ id: "storeFilterBar.input.status.all" }) },
          ...translatedStoreStatus
        ]}
        autoWidth
      />

      <SelectComponent
        onChange={onEditChange}
        value={currentEditStatus}
        options={[
          { id: 0, name: intl.formatMessage({ id: "storeFilterBar.input.editStatus.all" }) },
          ...translatedEditStatus
        ]}
        autoWidth
      />

      <SelectComponent
        onChange={onPlatformChange}
        value={currentPlatform}
        options={[
          { id: 0, name: intl.formatMessage({ id: "storeFilterBar.input.platform.all" }) },
          ...translatedPlatforms
        ]}
        autoWidth
      />

      {role === "admin" && (
        <ClickableComponent
          title={intl.formatMessage({ id: "storeFilterBar.button.addShop" })}
          height={40}
          icon={Plus}
          iconSize="20px"
          iconFillColor="#ffffff"
          iconPosition="left"
          onClick={() => {
            setIsAddMerchantOpen(true);
          }}
        />
      )}

      <ModalComponent
        title={intl.formatMessage({ id: "storeFilterBar.modal.title.addShop" })}
        isModalOpen={isAddMerchantOpen}
        onCloseModal={() => {
          setIsAddMerchantOpen(false);
        }}
        variant="big"
      >
        <CreateMerchantModal onCancel={() => setIsAddMerchantOpen(false)} />
      </ModalComponent>
    </div>
  );
};

export { MerchantFilterBar };
