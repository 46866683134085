import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useIntl } from "react-intl";
import Select, {
  components,
  ClearIndicatorProps,
  DropdownIndicatorProps,
  GroupBase,
  OptionsOrGroups,
  Props,
  SelectInstance
} from "react-select";
import Creatable from "react-select/creatable";
import AsyncCreatable from "react-select/async-creatable";
import AsyncSelect from "react-select/async";
import ArrowDropDownIcn from "@assets/icons/rounded/arrow-down.svg";
import CloseIcn from "@assets/icons/rounded/close.svg";
import { FilterItem } from "@app/api/core/filter/filter-item";
import { IconComponent } from "@app/core/icon";

import { FormLabelComponent, IFormLabelComponentProps } from "../form-label";
import { getReactSelectCustomStyles } from "./react-select-custom-styles";
import styles from "./react-select.module.scss";

export interface IReactSelectProps<
  isMulti extends boolean = false,
  Group extends GroupBase<FilterItem> = GroupBase<FilterItem>
> extends Props<FilterItem, isMulti> {
  isMulti?: isMulti;
  isCreatable?: boolean;
  isAsync?: boolean;
  label?: IFormLabelComponentProps;
  loadOptions?: (inputValue: string, callback: (options: OptionsOrGroups<FilterItem, Group>) => void) => any;
  enableCollapsing?: boolean;
  useDefaultComponents?: boolean;
}

export type IReactSelect = <isMulti extends boolean>(props: IReactSelectProps<isMulti>) => JSX.Element;

export const ReactSelect: IReactSelect = ({
  isCreatable,
  isAsync,
  label,
  enableCollapsing,
  useDefaultComponents,
  ...rest
}) => {
  const intl = useIntl();
  const [inputHasMaxHeight, setInputHasMaxHeight] = useState<boolean>(Boolean(enableCollapsing));
  const [inputIsMultiLine, setInputIsMultiLine] = useState<boolean>(false);
  const customStyles = getReactSelectCustomStyles(inputHasMaxHeight, Boolean(label?.errorMessage));
  const [inputValue, setInputValue] = useState<string | undefined>(rest.inputValue);
  const ref = useRef<SelectInstance<FilterItem, boolean, GroupBase<FilterItem>>>(null);

  useEffect(() => {
    setInputValue(rest.inputValue);
  }, [rest.inputValue]);

  useEffect(() => {
    const isMultiLine = (ref.current?.inputRef?.offsetTop || 0) > 15;
    setInputIsMultiLine(isMultiLine);
  }, [rest.value, ref.current?.inputRef?.offsetTop]);

  const noOptionsMessage = (): string =>
    inputValue || typeof inputValue === "undefined"
      ? intl.formatMessage({ id: "general.combobox.noResults" })
      : intl.formatMessage({ id: "general.combobox.noInputValue" });

  const customComponents = getComponents(useDefaultComponents, rest.components);

  const defaultSettings = {
    styles: customStyles,
    onInputChange: (value: string) => setInputValue(value),
    inputValue,
    components: customComponents,
    noOptionsMessage,
    inputId: rest.id,
    placeholder: intl.formatMessage({
      id: "general.combobox.placeholder"
    }),
    ref
  };

  return (
    <div className={styles.reactSelect}>
      {label && <FormLabelComponent {...label} />}
      {enableCollapsing && inputIsMultiLine && (
        <button
          type="button"
          className={clsx(styles.showAllFilters, label?.tooltip && styles.withTooltip)}
          onClick={() => setInputHasMaxHeight(!inputHasMaxHeight)}
        >
          {intl.formatMessage(
            {
              id: inputHasMaxHeight ? "general.combobox.showMore" : "general.combobox.showLess"
            },
            { amount: Array.isArray(rest.value) && rest.value.length }
          )}
        </button>
      )}
      {!isCreatable && !isAsync ? (
        /* @ts-expect-error ref definition is wrong */
        <Select {...defaultSettings} {...rest} />
      ) : isCreatable && !isAsync ? (
        /* @ts-expect-error ref definition is wrong */
        <Creatable
          {...defaultSettings}
          formatCreateLabel={(value) => intl.formatMessage({ id: "general.combobox.newResult" }, { value })}
          {...rest}
        />
      ) : !isCreatable && isAsync ? (
        /* @ts-expect-error ref definition is wrong */
        <AsyncSelect {...defaultSettings} {...rest} />
      ) : (
        /* @ts-expect-error ref definition is wrong */
        <AsyncCreatable
          {...defaultSettings}
          formatCreateLabel={(value) => intl.formatMessage({ id: "general.combobox.newResult" }, { value })}
          {...rest}
        />
      )}
      {label?.errorMessage && <span className={styles.errorMessage}>{label?.errorMessage}</span>}
    </div>
  );
};

const getComponents = (useDefaultComponents?: boolean, overrideComponents?: any) => {
  if (useDefaultComponents || overrideComponents) {
    return {
      DropdownIndicator,
      ClearIndicator,
      ...overrideComponents
    };
  }

  return {
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null
  };
};

const ClearIndicator = (props: ClearIndicatorProps<FilterItem, false>) => {
  return (
    <components.ClearIndicator {...props}>
      <IconComponent icon={CloseIcn} size="18px" fillColor="currentColor" />
    </components.ClearIndicator>
  );
};

const DropdownIndicator = (props: DropdownIndicatorProps<FilterItem, false>) => {
  return (
    <components.DropdownIndicator {...props}>
      <IconComponent icon={ArrowDropDownIcn} size="20px" fillColor="currentColor" />
    </components.DropdownIndicator>
  );
};
