import { useIntl } from "react-intl";
import { useEffect, useState } from "react";

import AutoRenew from "@assets/icons/rounded/auto-renew.svg";
import { basicSlugify } from "@app/util/slugify";

import styles from "./slugify-text-field.module.scss";
import { ITextFieldProps, TextFieldComponent } from "../text-field";
import { IconComponent } from "../icon";

export interface ISlugifyTextFieldProps extends ITextFieldProps {
  onGenerateSlugClick: () => void;
  disabledGeneration?: boolean;
  buttonTitle?: string;
}

export const SlugifyTextField = ({
  onGenerateSlugClick,
  disabledGeneration,
  buttonTitle,
  ...rest
}: ISlugifyTextFieldProps): JSX.Element => {
  const intl = useIntl();
  const [invalidSlug, setInvalidSlug] = useState<boolean>(false);

  useEffect(() => {
    if (rest.value !== basicSlugify(rest.value)) {
      setInvalidSlug(true);
    } else {
      setInvalidSlug(false);
    }
  }, [rest.value]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.slugifyTextField}>
        <TextFieldComponent
          {...rest}
          label={{
            ...rest.label,
            tooltip: rest.label?.tooltip || intl.formatMessage({ id: "slugifyInput.tooltip" })
          }}
          helperText={invalidSlug ? intl.formatMessage({ id: "slugifyInput.invalid" }) : undefined}
        />

        <button
          onClick={onGenerateSlugClick}
          type="button"
          title={buttonTitle || intl.formatMessage({ id: "slugifyInput.title" })}
          aria-label={intl.formatMessage({ id: "slugifyInput.title" })}
          className={styles.generate}
          disabled={rest.disabled || disabledGeneration}
        >
          <IconComponent icon={AutoRenew} size="16px" />
        </button>
      </div>
    </div>
  );
};
