import React, { useState } from "react";
import { withFormik, FormikProps, Form } from "formik";
import { TextFieldComponent, ClickableComponent, ResourceTextComponent } from "@app/core";
import { IntlShape } from "react-intl";
import Bin from "@assets/icons/rounded/bin.svg";
import styles from "./delete-user-account-form.module.scss";

interface IFormValues {
  securityWord: string;
}

interface IOtherProps {
  intl: IntlShape;
}

const InnerForm = (props: IOtherProps & FormikProps<IFormValues>) => {
  const { touched, errors } = props;

  const [formIsTouched, setFormIsTouched] = useState(false);

  const handleChange = (values: any) => {
    props.handleChange(values);

    if (!formIsTouched) {
      setFormIsTouched(true);
    }
  };

  return (
    <Form>
      <div className={styles.dataBlock}>
        <div className={styles.label}>
          <span>
            <ResourceTextComponent resourceKey="userAccountDetail.mainUserAccountInfo.deleteUserAccountForm.title" />
          </span>
        </div>

        <div className={styles.fieldsContainer}>
          <div className={styles.fields}>
            <div className={styles.formField}>
              <TextFieldComponent
                height={40}
                value={props.values.securityWord}
                onChange={handleChange}
                id="securityWord"
                type="text"
                errorMessage={touched.securityWord && errors.securityWord}
                label={{
                  label: props.intl.formatMessage({
                    id: "userAccountDetail.mainUserAccountInfo.deleteUserAccountForm.input.securityWord.label"
                  }),
                  tooltip: props.intl.formatMessage({
                    id: "userAccountDetail.mainUserAccountInfo.deleteUserAccountForm.input.securityWord.tooltip"
                  })
                }}
                placeholder={props.intl.formatMessage({
                  id: "userAccountDetail.mainUserAccountInfo.deleteUserAccountForm.input.securityWord.title"
                })}
              />
            </div>
            <div className={styles.formField}>
              <div className={styles.buttonSubmit}>
                <ClickableComponent
                  buttonType="submit"
                  height={40}
                  icon={Bin}
                  iconSize="16px"
                  iconPosition="left"
                  variant="secondary-inverted"
                  iconFillColor="currentColor"
                  disabled={!formIsTouched}
                  title={props.intl.formatMessage({
                    id: "userAccountDetail.mainUserAccountInfo.deleteUserAccountForm.button.title"
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

interface IMyFormProps {
  intl: IntlShape;
  onSubmit: (values: any) => void;
}

export const DeleteUserAccountForm = withFormik<IMyFormProps, IFormValues>({
  mapPropsToValues: () => ({
    securityWord: ""
  }),

  validate: (values: IFormValues, props: IMyFormProps) => {
    const errors: any = {};

    const securityWord = props.intl.formatMessage({
      id: "userAccountDetail.mainUserAccountInfo.deleteUserAccountForm.input.securityWord.title"
    });

    if (values.securityWord !== securityWord) {
      errors.securityWord = props.intl.formatMessage({
        id: "userAccountDetail.mainUserAccountInfo.deleteUserAccountForm.input.securityWord.error"
      });
    }

    return errors;
  },

  handleSubmit: (values, bag) => {
    bag.props.onSubmit(values);
  }
})(InnerForm);
