import React from "react";
import { withFormik, FormikProps, FormikErrors, Form } from "formik";
import { IntlShape } from "react-intl";

import { TextFieldComponent, ClickableComponent } from "@app/core";

import styles from "./reset-password-component.module.scss";

export interface IResetPasswordFormValues {
  confirmPassword: string;
  password: string;
}

interface IOtherProps {
  intl: IntlShape;
}

const InnerForm = (props: IOtherProps & FormikProps<IResetPasswordFormValues>) => {
  const { touched, errors } = props;

  return (
    <Form>
      <div className={styles.form}>
        <div className={styles.formField}>
          <TextFieldComponent
            label={{
              label: props.intl.formatMessage({ id: "resetPasswordForm.input.newPassword.label" }),
              errorMessage: touched.password === true && errors.password
            }}
            placeholder=""
            value={props.values.password}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            type="password"
            id="password"
            errorMessage={touched.password === true && errors.password}
          />
        </div>
        <div className={styles.formField}>
          <TextFieldComponent
            label={{
              label: props.intl.formatMessage({ id: "resetPasswordForm.input.confirmNewPassword.label" }),
              errorMessage: touched.confirmPassword === true && errors.confirmPassword
            }}
            placeholder=""
            value={props.values.confirmPassword}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            type="password"
            id="confirmPassword"
            errorMessage={touched.confirmPassword === true && errors.confirmPassword}
          />
        </div>
      </div>
      <div className={styles.submitButton}>
        <ClickableComponent
          buttonType="submit"
          title={props.intl.formatMessage({ id: "resetPasswordForm.button.submitNewPassword" })}
          fullWidth
          height={40}
        />
      </div>
    </Form>
  );
};

interface IFormProps {
  intl: IntlShape;
  onSubmit: (values: IResetPasswordFormValues) => void;
}

export const ResetPasswordFormComponent = withFormik<IFormProps, IResetPasswordFormValues>({
  mapPropsToValues: () => ({
    confirmPassword: "",
    password: ""
  }),

  validate: (values: IResetPasswordFormValues, props) => {
    const errors: FormikErrors<IResetPasswordFormValues> = {};
    if (!values.password) {
      errors.password = props.intl.formatMessage({ id: "resetPasswordForm.error.password.required" });
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = props.intl.formatMessage({ id: "resetPasswordForm.error.confirmPassword.required" });
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = props.intl.formatMessage({ id: "resetPasswordForm.error.confirmPassword.noMatch" });
    }

    return errors;
  },

  handleSubmit: (values, bag) => {
    bag.props.onSubmit(values);
  }
})(InnerForm);
