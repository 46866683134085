import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { useParams, useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";

import { ClickableComponent } from "@app/core";
import BackIcon from "@assets/icons/rounded/arrow-back.svg";
import BinIcon from "@assets/icons/rounded/bin.svg";
import { ModalComponent } from "@app/core/modal";
import { DeleteModal } from "@app/modules/delete-modal/delete-modal.component";
import { BasicModal } from "@app/modules/basic-modal";
import { CreateProductDTO, InProductDTO, OutProductDTO } from "@app/api/generated";
import { useAppDispatch, useAppSelector } from "@app/redux/store";
import { productsThunk } from "@app/redux/thunks/products.thunk";
import { productsActions } from "@app/redux/reducers/products";
import { ROUTES } from "@app/constants/routes";
import { PageCard } from "@app/components/page-card/page-card";

import { ProductForm } from "./form/product.form";
import styles from "./product-detail-component.module.scss";

const ProductDetail = () => {
  const { id } = useParams<{ id: string }>();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { selectedProduct } = useAppSelector((state) => state.products);
  const history = useNavigate();

  const [isDeleteProductOpen, setIsDeleteProductOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isLeaveWarningOpen, setIsLeaveWarningOpen] = useState<boolean>(false);
  const [isProductEdited, setIsProductEdited] = useState<boolean>(false);
  const editStateForEventListeners = useRef(isProductEdited);

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("beforeunload", checkReload);

    return () => {
      window.removeEventListener("beforeunload", checkReload);
      dispatch(productsActions.setSelectedProduct(undefined));
    };
  }, []);

  useEffect(() => {
    fetchProduct(id);
  }, [id]);

  const fetchProduct = async (productId?: string) => {
    if (productId !== "new") {
      const result: OutProductDTO | undefined = (await dispatch(productsThunk.getProduct(Number(productId)))) as any;

      if (!result) {
        history(ROUTES.PRODUCTS);
      }
    }
  };

  useEffect(() => {
    editStateForEventListeners.current = isProductEdited;
  }, [isProductEdited]);

  const checkReload = (e: BeforeUnloadEvent) => {
    if (editStateForEventListeners.current) {
      e.preventDefault();
      e.returnValue = "";
    }
  };

  const onDelete = async () => {
    onCancel(false);
    if (selectedProduct) {
      await dispatch(productsThunk.deleteProduct(selectedProduct.id));

      history(ROUTES.PRODUCTS);
    }
    setIsLeaveWarningOpen(false);
  };

  const onCancel = (isSavedCheck: boolean) => {
    if (isProductEdited && isSavedCheck) {
      setIsLeaveWarningOpen(true);
    } else {
      setIsLeaveWarningOpen(false);
      cleanEditState();
      history(ROUTES.PRODUCTS);
    }
  };

  const onSave = async (values: InProductDTO | CreateProductDTO) => {
    let returnValue: OutProductDTO | undefined;

    setIsSubmitting(true);
    if ("id" in values) {
      returnValue = (await dispatch(productsThunk.saveProduct(values))) as any;
    } else {
      returnValue = (await dispatch(productsThunk.createProduct(values))) as any;
    }
    setIsSubmitting(false);

    if (returnValue) {
      toast.success(intl.formatMessage({ id: "products.detail.form.submit.notify.success" }));
      cleanEditState();

      if (id === "new") {
        history(`${ROUTES.PRODUCTS}/${returnValue.id}`);
      }
    }
  };

  const onEdit = () => {
    if (!isProductEdited) {
      setIsProductEdited(true);
    }
  };

  const cleanEditState = () => {
    setIsProductEdited(false);
  };

  return (
    <div className={styles.detail}>
      <div className={styles.topBar}>
        <div className={clsx(styles.container, styles.topBarContainer)}>
          <div className={styles.topBarLeft}>
            <ClickableComponent
              variant="link-primary"
              title={
                selectedProduct
                  ? selectedProduct?.title
                  : intl.formatMessage({
                      id: "products.detail.titleNew"
                    })
              }
              icon={BackIcon}
              iconPosition="left"
              iconSize="24px"
              onClick={() => onCancel(true)}
            />
          </div>
          {id !== "new" && (
            <div className={styles.topBarRight}>
              <ClickableComponent
                variant="tertiary"
                iconStyle="filled"
                title={intl.formatMessage({ id: "products.detail.delete" })}
                buttonType="button"
                icon={BinIcon}
                iconPosition="left"
                iconSize="20px"
                height={40}
                onClick={() => setIsDeleteProductOpen(true)}
              />
            </div>
          )}
        </div>
      </div>

      <div className={styles.container}>
        <PageCard isForm isLoading={!selectedProduct && id !== "new"}>
          <ProductForm
            intl={intl}
            onSubmit={onSave}
            onCancel={onCancel}
            onEdit={onEdit}
            product={selectedProduct}
            disableSaveButton={!isProductEdited}
            isSubmittingValues={isSubmitting}
            isCreating={id === "new"}
          />
        </PageCard>
      </div>

      <ModalComponent
        title={intl.formatMessage({ id: "products.detail.modal.delete.title" })}
        isModalOpen={isDeleteProductOpen}
        onCloseModal={() => {
          setIsDeleteProductOpen(false);
        }}
        variant="big"
      >
        <DeleteModal
          onCancel={() => {
            setIsDeleteProductOpen(false);
          }}
          onDelete={onDelete}
        />
      </ModalComponent>

      <ModalComponent
        title={intl.formatMessage({ id: "general.modal.leavePage.title" })}
        isModalOpen={isLeaveWarningOpen}
        onCloseModal={() => setIsLeaveWarningOpen(false)}
        variant="big"
      >
        <BasicModal
          onApprove={() => onCancel(false)}
          onCancel={() => setIsLeaveWarningOpen(false)}
          onApproveTitle={intl.formatMessage({ id: "general.modal.leavePage.approve" })}
          onCancelTitel={intl.formatMessage({ id: "general.modal.leavePage.cancel" })}
          description={intl.formatMessage({ id: "general.modal.leavePage.description" })}
        />
      </ModalComponent>
    </div>
  );
};

export { ProductDetail };
