import React, { useEffect } from "react";
import { toast, Slide } from "react-toastify";
import { useIntl } from "react-intl";
import clsx from "clsx";
import "react-toastify/dist/ReactToastify.min.css";
import { Link } from "react-router-dom";

import { ImageComponent } from "@app/core";
import { ToastComponent } from "@app/core/toast";
import { userAccountThunks } from "@app/redux/thunks/user-account.thunk";
import { useAppDispatch, useAppSelector } from "@app/redux/store";
import { logout } from "@app/authorization/authorization-api";

import { ROUTES } from "@app/constants/routes";
import { NavBarItem } from "./nav-bar-item.component";
import { NavBarSettingsItem } from "./nav-bar-settings-item/nav-bar-settings-item.component";
import { NavBarLanguageSwitcher } from "./language-switcher/language-switcher.component";
import styles from "./nav-bar-component.module.scss";

const NavBar = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { role } = useAppSelector((state) => state.userAccount);

  useEffect(() => {
    dispatch(userAccountThunks.getRole());
    dispatch(userAccountThunks.getUserId());
  }, []);

  const onLogout = async () => {
    logout(dispatch);
  };

  return (
    <div className={styles.navBar}>
      <div className={clsx(styles.container, styles.items)}>
        <div className={styles.itemsLeft}>
          <div className={styles.image}>
            <Link to={ROUTES.STORE_OVERVIEW} className={styles.imageContainer}>
              <ImageComponent
                src="https://viper-development-images.s3-eu-west-1.amazonaws.com/blackFriday/logo-bf-nederland.svg"
                isBlocking
                width="184px"
                height="50px"
              />
            </Link>
          </div>
          <NavBarItem text={intl.formatMessage({ id: "navBarComponent.item.shops" })} link={ROUTES.STORE_OVERVIEW} />
          {role === "admin" && (
            <>
              <NavBarItem text={intl.formatMessage({ id: "navBarComponent.item.deals" })} link={ROUTES.DEAL_OVERVIEW} />
              <NavBarItem
                text={intl.formatMessage({ id: "navBarComponent.item.banners" })}
                link={ROUTES.BANNER_OVERVIEW}
              />
              <NavBarItem
                text={intl.formatMessage({ id: "navBarComponent.item.featCategories" })}
                link={ROUTES.FEATURED_CATEGORY_OVERVIEW}
              />
              <NavBarItem
                text={intl.formatMessage({ id: "navBarComponent.item.externalDealFilters" })}
                link={ROUTES.EXTERNAL_DEAL_FILTERS}
              />
              <NavBarItem text={intl.formatMessage({ id: "navBarComponent.item.products" })} link={ROUTES.PRODUCTS} />
              <NavBarItem
                text={intl.formatMessage({ id: "navBarComponent.item.filters" })}
                link={ROUTES.FILTER_OVERVIEW}
              />
              <NavBarItem text={intl.formatMessage({ id: "navBarComponent.item.users" })} link={ROUTES.USER_OVERVIEW} />
            </>
          )}
        </div>
        <div className={styles.itemsRight}>
          <NavBarLanguageSwitcher />
          <NavBarSettingsItem role={role} onLogout={onLogout} />
        </div>
      </div>
      <ToastComponent transition={Slide} position={toast.POSITION.TOP_CENTER} draggable />
    </div>
  );
};

export { NavBar };
