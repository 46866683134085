import * as React from "react";
import clsx from "clsx";

import PlusIcon from "@assets/icons/rounded/add.svg";
import MinusIcon from "@assets/icons/rounded/minus.svg";
import styles from "./number-input-component.module.scss";
import { IconComponent } from "../icon";
import { FormLabelComponent, IFormLabelComponentProps } from "../form-label";

export interface INumberInputProps {
  customButtons?: boolean;
  id: string;
  label?: IFormLabelComponentProps;
  maxValue?: number;
  minValue?: number;
  onChange: any;
  step?: string;
  type: string;
  value?: number;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
}

const NumberInput = (props: INumberInputProps) => {
  const [internalValue, setInternalValue] = React.useState<string>(
    props.value || props.value === 0 ? props.value.toString() : ""
  );

  React.useEffect(() => {
    setInternalValue(props.value || props.value === 0 ? props.value.toString() : "");
  }, [props.value]);

  const inputChanged = (e: React.ChangeEvent<any>) => {
    e.preventDefault();

    if (e.target.validity.valid) {
      const { value } = e.target;
      setInternalValue(value);

      if (props.onChange) {
        props.onChange(Number(value), props.id);
      }
    }
  };

  const onAdd = () => {
    let value: number;

    if (internalValue) {
      let newValue = Number(internalValue) + 1;

      if (props.maxValue || (props.maxValue === 0 && newValue > props.maxValue)) {
        newValue = props.maxValue;
      }

      value = newValue;
    } else {
      value = props.minValue || 0;
    }

    setInternalValue(value.toString());

    if (props.onChange) {
      props.onChange(value, props.id);
    }
  };

  const onSubtract = () => {
    let value: number;

    if (internalValue) {
      // eslint-disable-next-line radix
      let newValue = parseInt(internalValue, 0) - 1;

      if (props.minValue || (props.minValue === 0 && newValue < props.minValue)) {
        newValue = props.minValue;
      }

      value = newValue;
    } else {
      value = props.minValue || 0;
    }

    setInternalValue(value.toString());

    if (props.onChange) {
      props.onChange(value, props.id);
    }
  };

  const inputStyle = props.customButtons ? "customStyle" : "defaultStyle";

  return (
    <div className={styles.numberInput}>
      {props.label && <FormLabelComponent {...props.label} />}
      <div className={styles.inputContainer}>
        {props.customButtons && (
          <button type="button" className={clsx(styles.button, styles.minus)} onClick={onSubtract}>
            <div className={styles.icon}>
              <IconComponent icon={MinusIcon} size="16px" fillColor="currentColor" />
            </div>
          </button>
        )}

        <input
          id={props.id}
          role="spinbutton"
          type={props.type}
          min={props.minValue ?? ""}
          max={props.maxValue ?? ""}
          aria-valuemin={props.minValue ? props.minValue : 0}
          aria-valuemax={props.maxValue ? props.maxValue : 0}
          aria-valuenow={Number(internalValue)}
          className={clsx(styles.input, styles[inputStyle])}
          step={props.step || ""}
          value={internalValue}
          pattern="^\d*(\.\d{0,2})?$"
          onChange={inputChanged}
          onBlur={props.onBlur}
        />
        {props.customButtons && (
          <button type="button" className={clsx(styles.button, styles.plus)} onClick={onAdd}>
            <div className={styles.icon}>
              <IconComponent icon={PlusIcon} size="16px" fillColor="currentColor" />
            </div>
          </button>
        )}
      </div>
      {props.label?.errorMessage && <span className={styles.error}>{props.label?.errorMessage}</span>}
    </div>
  );
};

export { NumberInput };
