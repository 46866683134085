import React, { useState, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import clsx from "clsx";

import { ClickableComponent } from "@app/core";
import BackIcon from "@assets/icons/rounded/arrow-back.svg";
import BinIcon from "@assets/icons/rounded/bin.svg";
import { ModalComponent } from "@app/core/modal";
import { DeleteModal } from "@app/modules/delete-modal/delete-modal.component";
import { BasicModal } from "@app/modules/basic-modal";
import { PutBannerDTO } from "@app/api/generated";
import { useParams, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@app/redux/store";
import { bannersThunks } from "@app/redux/thunks/banners.thunk";
import { setSelectedBanner } from "@app/redux/reducers/banners";
import { PageCard } from "@app/components/page-card/page-card";
import { ROUTES } from "@app/constants/routes";

import { BannerForm } from "./banner.form";
import styles from "./banner-detail-component.module.scss";

const BannerDetail = () => {
  const { id } = useParams<{ id: string }>();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { selectedBanner } = useAppSelector((state) => state.banners);

  const history = useNavigate();

  const [isDeleteBannerOpen, setIsDeleteBannerOpen] = useState<boolean>(false);
  const [isEditBannerOpen, setIsEditBannerOpen] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isBannerEdited, setIsBannerEdited] = useState<boolean>(false);
  const bannerEditStateForEventListeners = useRef(isBannerEdited);

  useEffect(() => {
    if (!!id) {
      dispatch(bannersThunks.getBanner(Number(id)));
    }

    window.scrollTo(0, 0);
    window.addEventListener("beforeunload", checkReload);

    return () => {
      window.removeEventListener("beforeunload", checkReload);
      dispatch(setSelectedBanner(undefined));
    };
  }, []);

  useEffect(() => {
    bannerEditStateForEventListeners.current = isBannerEdited;
  }, [isBannerEdited]);

  const checkReload = (e: BeforeUnloadEvent) => {
    if (bannerEditStateForEventListeners.current) {
      e.preventDefault();
      e.returnValue = "";
    }
  };

  const onDeleteBanner = () => {
    if (selectedBanner) {
      dispatch(bannersThunks.deleteBanner(selectedBanner.id));

      setTimeout(() => {
        history(ROUTES.BANNER_OVERVIEW);
      }, 1000);
    }
    setIsDeleteBannerOpen(false);
  };

  const onBannerCancel = (isSavedCheck: boolean) => {
    if (isSavedCheck) {
      setIsEditBannerOpen(true);
    } else {
      setIsEditBannerOpen(false);
      cleanEditState();
      history(ROUTES.BANNER_OVERVIEW);
    }
  };

  const onBannerSave = async (values: PutBannerDTO) => {
    setIsSaving(true);
    await dispatch(bannersThunks.saveBanner(values));
    cleanEditState();
    window.scrollTo(0, 0);
  };

  const onEdit = () => {
    if (!isBannerEdited) {
      setIsBannerEdited(true);
    }
  };

  const cleanEditState = () => {
    setIsBannerEdited(false);
    setIsSaving(false);
  };

  const bannerTitle = selectedBanner
    ? selectedBanner.name
    : intl.formatMessage({ id: "bannerDetail.button.newBanner" });

  return (
    <div className={styles.bannerDetail}>
      <div className={styles.topBar}>
        <div className={clsx(styles.container, styles.topBarContainer)}>
          <div className={styles.topBarLeft}>
            <ClickableComponent
              variant="link-primary"
              title={bannerTitle}
              icon={BackIcon}
              iconPosition="left"
              iconSize="24px"
              onClick={() => {
                onBannerCancel(false);
              }}
            />
          </div>
          <div className={styles.topBarRight}>
            <ClickableComponent
              variant="tertiary"
              title={intl.formatMessage({ id: "bannerDetail.button.deleteBanner" })}
              buttonType="button"
              icon={BinIcon}
              iconPosition="left"
              iconSize="20px"
              iconFillColor="currentColor"
              height={40}
              onClick={() => setIsDeleteBannerOpen(true)}
            />
          </div>
        </div>
      </div>

      <div className={styles.container}>
        <PageCard isForm isLoading={!selectedBanner}>
          {selectedBanner && (
            <BannerForm
              onSubmit={onBannerSave}
              onCancel={onBannerCancel}
              onEdit={onEdit}
              banner={selectedBanner}
              intl={intl}
              disableSaveButton={!isBannerEdited}
              isSubmittingValues={isSaving}
            />
          )}
        </PageCard>
      </div>

      <ModalComponent
        title={intl.formatMessage({ id: "bannerDetail.modal.deleteBanner.title" })}
        isModalOpen={isDeleteBannerOpen}
        onCloseModal={() => {
          setIsDeleteBannerOpen(false);
        }}
        variant="big"
      >
        <DeleteModal
          onCancel={() => {
            setIsDeleteBannerOpen(false);
          }}
          onDelete={onDeleteBanner}
        />
      </ModalComponent>

      <ModalComponent
        title={intl.formatMessage({ id: "bannerDetail.modal.leavePage.title" })}
        isModalOpen={isEditBannerOpen}
        onCloseModal={() => {
          setIsEditBannerOpen(false);
        }}
        variant="big"
      >
        <BasicModal
          onApprove={() => onBannerCancel(false)}
          onCancel={() => setIsEditBannerOpen(false)}
          onApproveTitle={intl.formatMessage({
            id: "bannerDetail.modal.leavePage.approve"
          })}
          onCancelTitel={intl.formatMessage({
            id: "bannerDetail.modal.leavePage.cancel"
          })}
          description={intl.formatMessage({
            id: "bannerDetail.modal.leavePage.description"
          })}
        />
      </ModalComponent>
    </div>
  );
};

export { BannerDetail };
