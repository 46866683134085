import React from "react";

import { IconComponent } from "@app/core";
import VisibilityIcn from "@assets/icons/rounded/visibility.svg";
import VisibilityOffIcn from "@assets/icons/rounded/visibility-off.svg";
import { BannersExtendedGetStatusEnum } from "@app/api/generated";

interface IProps {
  status: string;
}

export const BannerStatusIcon = (props: IProps) => {
  if (props.status === BannersExtendedGetStatusEnum.VISIBLE) {
    return <IconComponent fillColor="#00A82F" icon={VisibilityIcn} size="18px" />;
  }

  if (props.status === BannersExtendedGetStatusEnum.INVISIBLE) {
    return <IconComponent fillColor="#E10A14" icon={VisibilityOffIcn} size="18px" />;
  }

  if (props.status === BannersExtendedGetStatusEnum.ALMOST_VISIBLE) {
    return <IconComponent fillColor="#FF8F00" icon={VisibilityOffIcn} size="18px" />;
  }

  return <IconComponent fillColor="#00A82F" icon={VisibilityIcn} size="18px" />;
};
