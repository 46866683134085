// From: https://github.com/mantinedev/mantine/blob/master/packages/@mantine/hooks/src/use-in-viewport/use-in-viewport.ts
import { useEffect, useMemo, useRef, useState } from "react";

export const useInViewport = <T extends HTMLElement = any>() => {
  const ref = useRef<T>(null);
  const [inViewport, setInViewport] = useState(false);

  const observer = useMemo(() => {
    if (typeof IntersectionObserver === "undefined") {
      return null;
    }
    return new IntersectionObserver(([entry]) => setInViewport(entry.isIntersecting));
  }, [ref]);

  useEffect(() => {
    if (ref.current && observer) {
      observer.observe(ref.current);
      return () => observer.disconnect();
    }
    // eslint-disable-next-line react/function-component-definition
    return () => null;
  }, []);

  return { ref, inViewport };
};
