import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";

import { IconComponent } from "@app/core";
import ArrowDropDownIcn from "@assets/icons/rounded/arrow-drop-down.svg";
import { setLocale } from "@app/redux/reducers/locales";
import { useAppSelector } from "@app/redux/store";
import useOutsideClick from "@app/util/outside-click";

import { LanguageSwitcherDropdownItem } from "./language-switcher-item";
import styles from "./language-switcher.module.scss";

export const NavBarLanguageSwitcher = () => {
  const dispatch = useDispatch();
  const { locale } = useAppSelector((state) => state.locales);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useOutsideClick([dropdownRef, buttonRef], () => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  const handleDropdownToggle = () => {
    setIsOpen(true);
  };

  const dropdownItems = [
    { id: "nl-NL", value: "NL" },
    { id: "en-GB", value: "EN" }
  ];

  return (
    <div className={styles.settings}>
      <button
        type="button"
        onClick={handleDropdownToggle}
        className={clsx(styles.settingsButton, isOpen && styles.active)}
        ref={buttonRef}
      >
        <span>{dropdownItems.find((item) => item.id === locale)?.value}</span>
        <IconComponent icon={ArrowDropDownIcn} size="20px" fillColor="white" />
      </button>
      <div className={styles.settingsDropdown} style={{ display: isOpen ? "initial" : "none" }} ref={dropdownRef}>
        {dropdownItems.map((item, index) => (
          <LanguageSwitcherDropdownItem
            key={index}
            locale={item.id}
            currentLocale={locale}
            setLocale={(newLocale) => {
              dispatch(setLocale(newLocale));
            }}
            value={item.value}
          />
        ))}
      </div>
    </div>
  );
};
