import React from "react";
import { withFormik, FormikProps, FormikErrors, Form } from "formik";
import { IntlShape } from "react-intl";

import { TextFieldComponent, ResourceTextComponent } from "@app/core";
import { SelectComponent } from "@app/core/select";
import { storeStatusType, storeStatus, storeStatusType2 } from "@app/api/core/merchant/merchant";
import { ImageUpload } from "@app/core/image-upload/image-upload.component";
import { RichEditor } from "@app/core/rich-editor";
import { NumberInput } from "@app/core/number-input/number-input.component";
import { CheckboxComponent } from "@app/core/checkbox";
import { OutDetailedMerchantDTO, InDetailedMerchantDTO, InCampaignDTO, OutCampaignDTO } from "@app/api/generated";
import { FilterItem } from "@app/api/core/filter/filter-item";
import { mapToFilterItem } from "@app/api/core/filter/map-filter-item";
import { platformType, platformTranslationKeys } from "@app/constants/platform";
import { IFilterType } from "@app/constants/filter";
import { ReactSelect } from "@app/core/react-select/react-select";

import { getFilters } from "./api-calls";
import styles from "./merchant-form.module.scss";
import { FormActions } from "@app/components/form-actions/form-actions";

export interface IMerchantFormValues {
  approvalRate?: number;
  buyInPosition?: number;
  eCPC?: number;
  features?: string;
  filters?: FilterItem[];
  minimumDealScoreModifier?: number;
  headerImage?: {
    alt?: string;
    id: number;
    title?: string;
    url: string;
  };
  id: number;
  inOverview: boolean;
  logoImage?: {
    alt?: string;
    id: number;
    title?: string;
    url: string;
  };
  name: string;
  platform: platformType;
  score?: number;
  seo?: {
    alternateHTML?: string;
    description?: string;
    jsonSchema?: string;
    pageText?: string;
    title?: string;
  };
  slug: string;
  status: storeStatusType;
  trackingUrl?: string;
  url?: string;
  adSenseScript?: string;
}

const InnerForm = (props: IMyFormProps & FormikProps<IMerchantFormValues>) => {
  const { touched, errors } = props;

  const generalAdvertiserEmailAdress = props.intl.formatMessage({ id: "general.advertiserEmailAdress" });

  const onCustomChange = (value: any, id: string) => {
    handleChange(value);
    props.setFieldValue(id, value);
  };

  const loadOptions = async (inputValue: string, callback: (options: FilterItem[]) => void) => {
    const filters = await getFilters(inputValue, "1;3");
    callback(filters ? filters.map(mapToFilterItem) : []);
  };

  const handleChange = (values: any) => {
    props.handleChange(values);
    props.onEdit();
  };

  const translatedPlatforms = platformTranslationKeys.map((untranslatedPlatform) => {
    return {
      id: untranslatedPlatform.id,
      name: props.intl.formatMessage({ id: untranslatedPlatform.name })
    } as IFilterType;
  });

  const translatedStoreStatus = storeStatus.map((untranslatedStoreStatus) => {
    return {
      id: untranslatedStoreStatus.id,
      name: props.intl.formatMessage({ id: untranslatedStoreStatus.name })
    } as storeStatusType2;
  });

  return (
    <Form>
      <div className={styles.form}>
        <div className={styles.dataBlock}>
          <div className={styles.label}>
            <ResourceTextComponent resourceKey="merchantDetailForm.dataBlock.generalInformation" />
          </div>
          <div className={styles.fieldsContainer}>
            <div className={styles.fields}>
              <div className={styles.formField}>
                <SelectComponent
                  height={40}
                  value={props.values.status}
                  onChange={handleChange}
                  options={translatedStoreStatus}
                  id="status"
                  disabled={props.role !== "admin"}
                  label={{
                    label: props.intl.formatMessage({ id: "merchantDetailForm.input.status.label" }),
                    tooltip: props.intl.formatMessage({ id: "merchantDetailForm.input.status.tooltip" })
                  }}
                />
              </div>
            </div>
            <div className={styles.fields}>
              <div className={styles.formField}>
                <TextFieldComponent
                  label={{
                    label: props.intl.formatMessage({ id: "merchantDetailForm.input.name.label" }),
                    tooltip: props.intl.formatMessage({ id: "merchantDetailForm.input.name.tooltip" }),
                    errorMessage: touched.name && errors.name
                  }}
                  placeholder={props.intl.formatMessage({ id: "merchantDetailForm.input.name.placeholder" })}
                  value={props.values.name}
                  onChange={handleChange}
                  onBlur={props.handleBlur}
                  disabled={props.role !== "admin"}
                  type="text"
                  id="name"
                  errorMessage={touched.name && errors.name}
                />
              </div>
            </div>
            <div className={styles.fields}>
              <div className={styles.formField}>
                <SelectComponent
                  height={40}
                  value={props.values.platform}
                  onChange={handleChange}
                  options={translatedPlatforms}
                  disabled={props.role !== "admin"}
                  id="platform"
                  label={{
                    label: props.intl.formatMessage({ id: "merchantDetailForm.input.platform.label" }),
                    tooltip: props.intl.formatMessage({ id: "merchantDetailForm.input.platform.tooltip" })
                  }}
                />
              </div>
              <div className={styles.formField}>
                <NumberInput
                  value={props.values.score}
                  onChange={onCustomChange}
                  id="score"
                  type="decimal"
                  label={{
                    label: props.intl.formatMessage({ id: "dealForm.input.score.label" }),
                    isOptionalField: true
                  }}
                />
              </div>
              <div className={styles.formField}>
                <NumberInput
                  label={{
                    label: props.intl.formatMessage({
                      id: "merchantDetailForm.input.minimumDealScoreModifier.label"
                    }),
                    tooltip: props.intl.formatMessage({
                      id: "merchantDetailForm.input.minimumDealScoreModifier.tooltip"
                    }),
                    isOptionalField: true
                  }}
                  onChange={onCustomChange}
                  value={props.values.minimumDealScoreModifier}
                  type="decimal"
                  id="minimumDealScoreModifier"
                />
              </div>
            </div>

            <div className={styles.fields}>
              <div className={styles.formField}>
                <TextFieldComponent
                  label={{
                    label: props.intl.formatMessage({ id: "merchantDetailForm.input.features.label" }),
                    tooltip: props.intl.formatMessage({ id: "merchantDetailForm.input.features.tooltip" })
                  }}
                  placeholder={props.intl.formatMessage({ id: "merchantDetailForm.input.features.placeholder" })}
                  value={props.values.features}
                  onChange={handleChange}
                  onBlur={props.handleBlur}
                  id="features"
                  helperText={props.intl.formatMessage({ id: "merchantDetailForm.input.features.helperText" })}
                  isTextArea
                  maxCharacters={270}
                />
              </div>
            </div>
            {props.role === "admin" && (
              <div className={styles.fields}>
                <div className={styles.formField}>
                  <ReactSelect
                    id="filters"
                    label={{
                      label: props.intl.formatMessage({ id: "merchantDetailForm.input.categories.label" }),
                      tooltip: props.intl.formatMessage({ id: "merchantDetailForm.input.categories.tooltip" })
                    }}
                    onBlur={props.handleBlur}
                    isClearable
                    value={props.values.filters}
                    loadOptions={loadOptions}
                    isAsync
                    isMulti
                    onChange={(e) => onCustomChange(e, `filters`)}
                    enableCollapsing
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.dataBlock}>
          <div className={styles.label}>
            <ResourceTextComponent resourceKey="merchantDetailForm.dataBlock.logo" />
          </div>
          <div className={styles.fieldsContainer}>
            <div className={styles.fields}>
              <div className={styles.formField}>
                <ImageUpload
                  label={{
                    label: props.intl.formatMessage({ id: "merchantDetailForm.input.logo.label" }),
                    tooltip: props.intl.formatMessage({ id: "merchantDetailForm.input.logo.tooltip" })
                  }}
                  id="logoImage"
                  onChange={onCustomChange}
                  image={props.values.logoImage}
                />
              </div>
              {props.role === "admin" && (
                <div className={styles.fieldsContainer}>
                  <div className={styles.formField}>
                    <TextFieldComponent
                      label={{
                        label: props.intl.formatMessage({ id: "merchantDetailForm.input.logo.title.label" }),
                        tooltip: props.intl.formatMessage({ id: "merchantDetailForm.input.logo.title.tooltip" })
                      }}
                      placeholder={props.intl.formatMessage({ id: "merchantDetailForm.input.logo.title.placeholder" })}
                      value={props.values.logoImage && props.values.logoImage.title}
                      onChange={handleChange}
                      onBlur={props.handleBlur}
                      type="text"
                      id="logoImage.title"
                      disabled={!props.values.logoImage}
                    />
                  </div>
                  <div className={styles.formField}>
                    <TextFieldComponent
                      label={{
                        label: props.intl.formatMessage({ id: "merchantDetailForm.input.logo.alt.label" }),
                        tooltip: props.intl.formatMessage({ id: "merchantDetailForm.input.logo.alt.tooltip" })
                      }}
                      placeholder={props.intl.formatMessage({ id: "merchantDetailForm.input.logo.alt.placeholder" })}
                      value={props.values.logoImage && props.values.logoImage.alt}
                      onChange={handleChange}
                      onBlur={props.handleBlur}
                      type="text"
                      id="logoImage.alt"
                      disabled={!props.values.logoImage}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.dataBlock}>
          <div className={styles.label}>
            <ResourceTextComponent resourceKey="merchantDetailForm.dataBlock.header" />
          </div>
          <div className={styles.fieldsContainer}>
            <div className={styles.fields}>
              <div className={styles.formField}>
                <ImageUpload
                  label={{
                    label: props.intl.formatMessage({ id: "merchantDetailForm.input.headerImage.label" }),
                    tooltip: props.intl.formatMessage({ id: "merchantDetailForm.input.headerImage.tooltip" }),
                    isOptionalField: true
                  }}
                  id="headerImage"
                  onChange={onCustomChange}
                  image={props.values.headerImage}
                />
              </div>
              {props.role === "admin" && (
                <div className={styles.fieldsContainer}>
                  <div className={styles.formField}>
                    <TextFieldComponent
                      label={{
                        label: props.intl.formatMessage({ id: "merchantDetailForm.input.headerImage.title.label" }),
                        tooltip: props.intl.formatMessage({ id: "merchantDetailForm.input.headerImage.title.tooltip" })
                      }}
                      placeholder={props.intl.formatMessage({
                        id: "merchantDetailForm.input.headerImage.title.placeholder"
                      })}
                      value={props.values.headerImage && props.values.headerImage.title}
                      onChange={handleChange}
                      onBlur={props.handleBlur}
                      type="text"
                      id="headerImage.title"
                      disabled={!props.values.headerImage}
                    />
                  </div>
                  <div className={styles.formField}>
                    <TextFieldComponent
                      label={{
                        label: props.intl.formatMessage({ id: "merchantDetailForm.input.headerImage.alt.label" }),
                        tooltip: props.intl.formatMessage({ id: "merchantDetailForm.input.headerImage.alt.tooltip" })
                      }}
                      placeholder={props.intl.formatMessage({
                        id: "merchantDetailForm.input.headerImage.alt.placeholder"
                      })}
                      value={props.values.headerImage && props.values.headerImage.alt}
                      onChange={handleChange}
                      onBlur={props.handleBlur}
                      type="text"
                      id="headerImage.alt"
                      disabled={!props.values.headerImage}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {props.role === "admin" && (
          <div className={styles.dataBlock}>
            <div className={styles.label}>
              <ResourceTextComponent resourceKey="merchantDetailForm.dataBlock.seoInformation" />
            </div>
            <div className={styles.fieldsContainer}>
              <div className={styles.fields}>
                <div className={styles.formField}>
                  <div className={styles.richEditor}>
                    <RichEditor
                      onChange={onCustomChange}
                      pageField={props.values.seo?.pageText || ""}
                      id="seo.pageText"
                      height={215}
                      maxHeight={400}
                      label={{
                        label: props.intl.formatMessage({ id: "merchantDetailForm.input.seoText.label" }),
                        tooltip: props.intl.formatMessage({ id: "merchantDetailForm.input.seoText.tooltip" })
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.fields}>
                <div className={styles.formField}>
                  <TextFieldComponent
                    label={{
                      label: props.intl.formatMessage({ id: "merchantDetailForm.input.seoText.meta.label" }),
                      tooltip: props.intl.formatMessage({ id: "merchantDetailForm.input.seoText.meta.tooltip" })
                    }}
                    placeholder={props.intl.formatMessage({ id: "merchantDetailForm.input.seoText.meta.placeholder" })}
                    value={props.values.seo && props.values.seo.title}
                    onChange={handleChange}
                    onBlur={props.handleBlur}
                    type="text"
                    id="seo.title"
                  />
                </div>
              </div>
              <div className={styles.fields}>
                <div className={styles.formField}>
                  <TextFieldComponent
                    label={{
                      label: props.intl.formatMessage({ id: "merchantDetailForm.input.seoText.description.label" }),
                      tooltip: props.intl.formatMessage({ id: "merchantDetailForm.input.seoText.description.tooltip" })
                    }}
                    placeholder={props.intl.formatMessage({
                      id: "merchantDetailForm.input.seoText.description.placeholder"
                    })}
                    value={props.values.seo && props.values.seo.description}
                    onChange={handleChange}
                    onBlur={props.handleBlur}
                    type="text"
                    id="seo.description"
                    isTextArea
                  />
                </div>
              </div>
              <div className={styles.fields}>
                <div className={styles.formField}>
                  <TextFieldComponent
                    label={{
                      label: props.intl.formatMessage({ id: "merchantDetailForm.input.seoText.hrefLang.label" }),
                      tooltip: props.intl.formatMessage({ id: "merchantDetailForm.input.seoText.hrefLang.tooltip" })
                    }}
                    placeholder={props.intl.formatMessage({
                      id: "merchantDetailForm.input.seoText.hrefLang.placeholder"
                    })}
                    value={props.values.seo && props.values.seo.alternateHTML}
                    onChange={handleChange}
                    onBlur={props.handleBlur}
                    type="text"
                    id="seo.alternateHTML"
                    isTextArea
                  />
                </div>
              </div>
              <div className={styles.fields}>
                <div className={styles.formField}>
                  <TextFieldComponent
                    label={{
                      label: props.intl.formatMessage({ id: "merchantDetailForm.input.jsonSchema.hrefLang.label" }),
                      tooltip: props.intl.formatMessage({ id: "merchantDetailForm.input.jsonSchema.hrefLang.tooltip" })
                    }}
                    placeholder={props.intl.formatMessage({
                      id: "merchantDetailForm.input.jsonSchema.hrefLang.placeholder"
                    })}
                    value={props.values.seo && props.values.seo.jsonSchema}
                    onChange={handleChange}
                    onBlur={props.handleBlur}
                    type="text"
                    id="seo.jsonSchema"
                    isTextArea
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className={styles.dataBlock}>
          <div className={styles.label}>
            <ResourceTextComponent resourceKey="merchantDetailForm.dataBlock.additionalInformation" />
          </div>
          <div className={styles.fieldsContainer}>
            {props.role === "admin" && (
              <div className={styles.fields}>
                <div className={styles.formField}>
                  <NumberInput
                    label={{
                      label: props.intl.formatMessage({ id: "merchantDetailForm.input.eCPC.label" }),
                      tooltip: props.intl.formatMessage({ id: "merchantDetailForm.input.eCPC.tooltip" })
                    }}
                    minValue={0}
                    onChange={onCustomChange}
                    value={props.values.eCPC}
                    type="number"
                    id="eCPC"
                    step="0.01"
                  />
                </div>
                <div className={styles.formField}>
                  <NumberInput
                    label={{
                      label: props.intl.formatMessage({ id: "merchantDetailForm.input.approvalRate.label" }),
                      tooltip: props.intl.formatMessage({ id: "merchantDetailForm.input.approvalRate.tooltip" })
                    }}
                    minValue={0}
                    onChange={onCustomChange}
                    value={props.values.approvalRate}
                    type="number"
                    id="approvalRate"
                    step="0.01"
                    maxValue={1}
                  />
                </div>

                <div className={styles.formField}>
                  <NumberInput
                    label={{
                      label: props.intl.formatMessage({ id: "merchantDetailForm.input.buyInPosition.label" }),
                      tooltip: props.intl.formatMessage({ id: "merchantDetailForm.input.buyInPosition.tooltip" }),
                      tooltipPosition: "start"
                    }}
                    minValue={0}
                    onChange={onCustomChange}
                    value={props.values.buyInPosition}
                    type="number"
                    id="buyInPosition"
                    customButtons
                  />
                </div>
              </div>
            )}
            {props.role === "admin" && (
              <div className={styles.fields}>
                <div className={styles.formField}>
                  <CheckboxComponent
                    name="inOverview"
                    onChange={handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.inOverview}
                  >
                    <ResourceTextComponent resourceKey="merchantDetailForm.input.inOverview" />
                  </CheckboxComponent>
                </div>
              </div>
            )}

            <div className={styles.fields}>
              <div className={styles.formField}>
                <TextFieldComponent
                  label={{
                    label: props.intl.formatMessage({ id: "merchantDetailForm.input.url.label" }),
                    tooltip: props.intl.formatMessage({ id: "merchantDetailForm.input.url.tooltip" }),
                    errorMessage: touched.url === true && errors.url
                  }}
                  placeholder={props.intl.formatMessage({ id: "merchantDetailForm.input.url.placeholder" })}
                  onChange={handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.url}
                  type="text"
                  id="url"
                  errorMessage={touched.url && errors.url}
                />
              </div>
            </div>
            {props.role === "admin" && (
              <div className={styles.fields}>
                <div className={styles.formField}>
                  <TextFieldComponent
                    label={{
                      label: props.intl.formatMessage({ id: "merchantDetailForm.input.trackingUrl.label" }),
                      tooltip: props.intl.formatMessage({ id: "merchantDetailForm.input.trackingUrl.tooltip" }),
                      errorMessage: touched.trackingUrl === true && errors.trackingUrl
                    }}
                    placeholder={props.intl.formatMessage({ id: "merchantDetailForm.input.trackingUrl.placeholder" })}
                    onChange={handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.trackingUrl}
                    type="text"
                    id="trackingUrl"
                    errorMessage={touched.trackingUrl && errors.trackingUrl}
                  />
                </div>
              </div>
            )}
            {props.role === "admin" && (
              <div className={styles.fields}>
                <div className={styles.formField}>
                  <TextFieldComponent
                    label={{
                      label: props.intl.formatMessage({ id: "merchantDetailForm.input.adSenseScript.label" }),
                      tooltip: props.intl.formatMessage({ id: "merchantDetailForm.input.adSenseScript.tooltip" }),
                      errorMessage: touched.adSenseScript === true && errors.adSenseScript
                    }}
                    placeholder={props.intl.formatMessage({ id: "merchantDetailForm.input.adSenseScript.placeholder" })}
                    onChange={handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.adSenseScript}
                    type="text"
                    id="adSenseScript"
                    errorMessage={touched.adSenseScript && errors.adSenseScript}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <FormActions
        disableSaveButton={props.disableSaveButton}
        isSubmittingValues={props.isSubmittingValues}
        onCancel={() => props.onCancel(false)}
        onSubmit={props.handleSubmit}
        hiddenSubmitRef={props.submitRef}
      >
        <div className={styles.complaints}>
          <ResourceTextComponent
            resourceKey="merchantDetailForm.complaints"
            values={{
              emailAdress: (
                <a href={`mailto:${generalAdvertiserEmailAdress}`} target="_blank" rel="noopener noreferrer">
                  {generalAdvertiserEmailAdress}
                </a>
              )
            }}
          />
        </div>
      </FormActions>
    </Form>
  );
};

interface IMyFormProps {
  disableSaveButton: boolean;
  intl: IntlShape;
  merchant: OutDetailedMerchantDTO;
  onCancel: (isSavedCheck: boolean) => void;
  onEdit: () => void;
  onSubmit: (values: InDetailedMerchantDTO) => void;
  role: string;
  submitRef?: React.RefObject<HTMLButtonElement>;
  isSubmittingValues?: boolean;
}

export const MerchantFormComponent = withFormik<IMyFormProps, IMerchantFormValues>({
  mapPropsToValues: (props: IMyFormProps) => ({
    status: props.merchant.live ? storeStatusType.live : storeStatusType.offline,
    id: props.merchant.id,
    inOverview: props.merchant.inOverview || false,
    name: props.merchant.name,
    slug: props.merchant.slug,
    features: props.merchant.features || "",
    platform: props.merchant.platform.id,
    headerImage: props.merchant.headerImage,
    logoImage: props.merchant.logoImage,
    minimumDealScoreModifier: props.merchant.minimumDealScoreModifier ?? undefined,
    seo: {
      alternateHTML: props.merchant.seo && props.merchant.seo.alternateHTML,
      title: props.merchant.seo && props.merchant.seo.title,
      description: props.merchant.seo && props.merchant.seo.description,
      pageText: props.merchant.seo && props.merchant.seo.pageText,
      jsonSchema: props.merchant.seo && props.merchant.seo.jsonSchema
    },
    buyInPosition: props.merchant.buyInPosition,
    eCPC: props.merchant.eCPC,
    url: props.merchant.url,
    trackingUrl: props.merchant.trackingUrl,
    // Map filters
    filters: props.merchant.filters ? props.merchant.filters.map(mapToFilterItem) : props.merchant.filters,
    score: props.merchant.score,
    approvalRate: props.merchant.approvalRate,
    adSenseScript: props.merchant.adSenseScript
  }),

  validate: (values: IMerchantFormValues, bag) => {
    const errors: FormikErrors<IMerchantFormValues> = {};
    if (!values.name) {
      errors.name = bag.intl.formatMessage({ id: "merchantDetailForm.errors.noName" });
    }

    if (bag.role !== "admin" && (!values.url || values.url.length === 0)) {
      errors.url = bag.intl.formatMessage({ id: "merchantDetailForm.errors.noUrl" });
    }

    return errors;
  },

  handleSubmit: (values, bag) => {
    const merchant = mapValuesToMerchant(values, bag.props.merchant);
    bag.props.onSubmit(merchant);
  }
})(InnerForm);

const mapValuesToMerchant = (values: IMerchantFormValues, merchant: OutDetailedMerchantDTO): InDetailedMerchantDTO => {
  const newMerchant: InDetailedMerchantDTO = {
    buyInPosition: values.buyInPosition,
    campaigns: merchant.campaigns?.map((campaign) => mapToInCampaign(campaign, merchant.id)),
    eCPC: values.eCPC,
    inOverview: values.inOverview,
    features: values.features,
    filters: values.filters ? values.filters.map((item) => ({ id: item.value })) : [],
    headerImage: values.headerImage ? values.headerImage : undefined,
    id: merchant.id,
    logoImage: values.logoImage ? values.logoImage : undefined,
    name: values.name,
    platform: { id: Number(values.platform), name: "", googleExperimentId: "", wctPlatformId: 0 },
    seo: {
      alternateHTML: values.seo && values.seo.alternateHTML,
      title: values.seo && values.seo.title,
      description: values.seo && values.seo.description,
      pageText: (values.seo && values.seo.pageText) || "",
      jsonSchema: values.seo && values.seo.jsonSchema
    },
    minimumDealScoreModifier: values.minimumDealScoreModifier === 0 ? null : values.minimumDealScoreModifier,
    // I know this is ugly, sue me.
    // eslint-disable-next-line eqeqeq
    live: values.status == storeStatusType.live,
    trackingUrl: values.trackingUrl,
    url: values.url,
    score: values.score,
    approvalRate: values.approvalRate,
    adSenseScript: values.adSenseScript
  };

  return newMerchant;
};

const mapToInCampaign = (outCampaign: OutCampaignDTO, merchantId: number): InCampaignDTO => {
  const result: InCampaignDTO = {
    ...outCampaign,
    merchantId
  };

  return result;
};
