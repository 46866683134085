import React from "react";
import { Route, Routes as ReactRouterRoutes, RouteProps, Navigate } from "react-router-dom";

import { RequireAuth } from "@app/util";

import {
  RequestPassword,
  Login,
  ResetPassword,
  MerchantDetailWrapper,
  MerchantOverview,
  FilterOverview,
  DealDetail,
  BannerDetail,
  BannerOverview,
  UserOverview,
  UserAccountDetail,
  PlatformSettings,
  DealOverview,
  FeaturedCategoryOverview,
  FeaturedCategoryDetail,
  ExternalDealFilterOverview,
  ExternalDealFilterDetail,
  NavBar
} from "./modules";
import { ROUTES } from "./constants/routes";
import { ProductOverview } from "./modules/product-overview";
import { ProductDetail } from "./modules/product-detail";

interface IProtectedRoutesProps extends Omit<RouteProps, "path"> {
  renderComponent: JSX.Element;
  path: string;
}

const PROTECTED_ROUTES: IProtectedRoutesProps[] = [
  {
    path: ROUTES.STORE_OVERVIEW,
    renderComponent: <MerchantOverview />
  },
  { path: ROUTES.STORE_OVERVIEW, renderComponent: <MerchantOverview /> },
  { path: ROUTES.BANNER_OVERVIEW, renderComponent: <BannerOverview /> },
  { path: `${ROUTES.MERCHANT_DETAIL}/:id`, renderComponent: <MerchantDetailWrapper /> },
  { path: ROUTES.FEATURED_CATEGORY_OVERVIEW, renderComponent: <FeaturedCategoryOverview /> },
  { path: `${ROUTES.FEATURED_CATEGORY_OVERVIEW}/:id`, renderComponent: <FeaturedCategoryDetail /> },
  { path: ROUTES.FILTER_OVERVIEW, renderComponent: <FilterOverview /> },
  { path: `${ROUTES.BANNER_DETAIL}/:id`, renderComponent: <BannerDetail /> },
  { path: ROUTES.DEAL_OVERVIEW, renderComponent: <DealOverview /> },
  { path: `${ROUTES.DEAL_DETAIL}/:id`, renderComponent: <DealDetail /> },
  { path: ROUTES.USER_OVERVIEW, renderComponent: <UserOverview /> },
  { path: ROUTES.MY_ACCOUNT, renderComponent: <UserAccountDetail /> },
  { path: ROUTES.PLATFORM_SETTINGS, renderComponent: <PlatformSettings /> },
  { path: ROUTES.EXTERNAL_DEAL_FILTERS, renderComponent: <ExternalDealFilterOverview /> },
  { path: `${ROUTES.EXTERNAL_DEAL_FILTERS}/:id`, renderComponent: <ExternalDealFilterDetail /> },
  { path: ROUTES.PRODUCTS, renderComponent: <ProductOverview /> },
  { path: `${ROUTES.PRODUCTS}/:id`, renderComponent: <ProductDetail /> }
];

const Routes: React.FunctionComponent = () => (
  <ReactRouterRoutes>
    {PROTECTED_ROUTES.map((route) => (
      <Route
        {...route}
        key={route.path}
        path={route.path}
        element={
          <RequireAuth>
            <>
              <NavBar />
              {route.renderComponent}
            </>
          </RequireAuth>
        }
      />
    ))}

    <Route path={ROUTES.LOGIN} element={<Login />} />
    <Route path={ROUTES.REQUEST_PASSWORD} element={<RequestPassword />} />
    <Route path={`${ROUTES.RESET_PASSWORD}/:token`} element={<ResetPassword />} />
    <Route path="*" element={<Navigate to={ROUTES.STORE_OVERVIEW} replace />} />
  </ReactRouterRoutes>
);

export { Routes };
