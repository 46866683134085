import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { ClickableComponent, TextFieldComponent } from "@app/core";
import { ModalComponent } from "@app/core/modal";
import Plus from "@assets/icons/rounded/add.svg";
import Search from "@assets/icons/rounded/search.svg";
import { SelectComponent } from "@app/core/select";
import { platformTranslationKeys } from "@app/constants/platform";
import { IFilterType } from "@app/constants/filter";
import { ReactSelect } from "@app/core/react-select/react-select";
import { useDebounce } from "@app/util/use-debounce";
import { FilterItem } from "@app/api/core/filter/filter-item";
import { mapMerchantToFilterItem } from "@app/api/core/filter/map-filter-item";
import { useAppDispatch, useAppSelector } from "@app/redux/store";
import { setCurrentMerchant, setCurrentPlatform, setCurrentSearch } from "@app/redux/reducers/featured-categories";

import { getMerchants } from "../deal-detail/components/api-calls";
import { CreateCategoryModal } from "../create-featured-category-modal";
import styles from "./featured-category-filter-bar-component.module.scss";

const CategoryFilterBar = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { currentSearch, currentPlatform, currentMerchant } = useAppSelector((state) => state.featuredCategories);

  const [search, setSearch] = useState<string>(currentSearch || "");
  const [isAddFeaturedCategoryOpen, setIsAddFeaturedCategoryOpen] = useState<boolean>(false);
  const debouncedSearchTerm = useDebounce(search, 500);

  const translatedPlatforms = platformTranslationKeys.map((untranslatedPlatform) => {
    return {
      id: untranslatedPlatform.id,
      name: intl.formatMessage({ id: untranslatedPlatform.name })
    } as IFilterType;
  });

  useEffect(() => {
    dispatch(setCurrentSearch(debouncedSearchTerm || undefined));
  }, [debouncedSearchTerm]);

  const onPlatformChange = (e: React.ChangeEvent<any>) => {
    const value = Number(e.target.value);
    dispatch(setCurrentPlatform(value || undefined));
  };

  const onMerchantChange = (value: FilterItem | null) => {
    dispatch(setCurrentMerchant(value || undefined));
  };

  const loadMerchants = async (inputValue: string, callback: (options: FilterItem[]) => void) => {
    const showPlatformInLabels = !currentPlatform;
    const merchants = await getMerchants(inputValue, currentPlatform);
    callback(merchants ? merchants.map((merchant) => mapMerchantToFilterItem(merchant, showPlatformInLabels)) : []);
  };

  return (
    <div className={styles.categoriesFilterBar}>
      <div className={styles.searchField}>
        <TextFieldComponent
          height={40}
          placeholder={intl.formatMessage({ id: "categoriesFilterBarComponent.searchPlaceholder" })}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          type="text"
          id="search"
          icon={Search}
          width={318}
        />
      </div>
      <div className={styles.merchantSelect}>
        <ReactSelect
          id="merchant"
          isClearable
          value={currentMerchant}
          placeholder={intl.formatMessage({ id: "dealFilterBarComponent.selectAllMerchants" })}
          loadOptions={loadMerchants}
          isAsync
          isMulti={false}
          onChange={onMerchantChange}
        />
      </div>

      <SelectComponent
        onChange={onPlatformChange}
        value={currentPlatform}
        options={[
          { id: 0, name: intl.formatMessage({ id: "dealFilterBarComponent.selectAllPlatforms" }) },
          ...translatedPlatforms
        ]}
        autoWidth
      />

      <ClickableComponent
        title={intl.formatMessage({ id: "categoriesFilterBarComponent.addCategory" })}
        height={40}
        icon={Plus}
        iconSize="20px"
        iconFillColor="#ffffff"
        iconPosition="left"
        onClick={() => {
          setIsAddFeaturedCategoryOpen(true);
        }}
      />

      <ModalComponent
        title={intl.formatMessage({ id: "categoriesFilterBarComponent.addCategory" })}
        isModalOpen={isAddFeaturedCategoryOpen}
        onCloseModal={() => {
          setIsAddFeaturedCategoryOpen(false);
        }}
        variant="big"
      >
        <CreateCategoryModal
          onSubmit={() => setIsAddFeaturedCategoryOpen(false)}
          onCancel={() => setIsAddFeaturedCategoryOpen(false)}
        />
      </ModalComponent>
    </div>
  );
};

export { CategoryFilterBar };
