import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";

import Bin from "@assets/icons/rounded/bin.svg";
import Edit from "@assets/icons/rounded/edit.svg";
import { IconComponent } from "@app/core";
import { useAppSelector } from "@app/redux";

import styles from "./table-actions-component.module.scss";

export interface TableActionsProps {
  editLink?: string;
  onEdit?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onDelete: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  protectDelete?: boolean;
}

const TableActions = ({ editLink, onEdit, onDelete, protectDelete }: TableActionsProps) => {
  const intl = useIntl();
  const { role } = useAppSelector((state) => state.userAccount);

  return (
    <div className={clsx(styles.push)}>
      {onEdit && (
        <button
          type="button"
          className={clsx(styles.smallButton, styles.editButton)}
          onClick={onEdit}
          title={intl.formatMessage({ id: "general.table.edit.title" })}
        >
          <IconComponent icon={Edit} size="18px" />
        </button>
      )}
      {editLink && (
        <Link
          to={editLink}
          className={clsx(styles.smallButton, styles.editButton)}
          title={intl.formatMessage({ id: "general.table.edit.title" })}
        >
          <IconComponent icon={Edit} size="18px" />
        </Link>
      )}
      {(!protectDelete || role === "admin") && (
        <button
          type="button"
          className={clsx(styles.smallButton, styles.removeButton)}
          onClick={onDelete}
          title={intl.formatMessage({ id: "general.table.delete.title" })}
        >
          <IconComponent icon={Bin} size="18px" />
        </button>
      )}
    </div>
  );
};

export { TableActions };
