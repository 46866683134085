import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OutProductDTO } from "@app/api/generated";

export interface IProductsState {
  products?: OutProductDTO[];
  selectedProduct?: OutProductDTO;
  currentPlatform?: number;
  currentSearch?: string;
  currentStatus?: number;
  totalPages: number;
  currentPage: number;
  totalResults: number;
  isLoading?: boolean;
}

const initialState: IProductsState = {
  products: [],
  currentPage: 1,
  totalPages: 1,
  totalResults: 0
};

export const slice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setProducts: (state, action: PayloadAction<OutProductDTO[]>) => {
      state.products = action.payload;
    },
    setTotalResults: (state, action: PayloadAction<number>) => {
      state.totalResults = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setSelectedProduct: (state, action: PayloadAction<OutProductDTO | undefined>) => {
      state.selectedProduct = action.payload;
    },
    deleteProductFromList: (state, action: PayloadAction<number>) => {
      state.products = state.products?.filter((filter) => filter.id !== action.payload);
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setTotalPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
    setCurrentStatus: (state, action: PayloadAction<number | undefined>) => {
      state.currentStatus = action.payload;
    },
    setCurrentPlatform: (state, action: PayloadAction<number | undefined>) => {
      state.currentPlatform = action.payload;
    },
    setCurrentSearch: (state, action: PayloadAction<string | undefined>) => {
      state.currentSearch = action.payload;
    }
  }
});

export const productsActions = slice.actions;

export default slice.reducer;
