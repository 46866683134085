import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";

import { useAppDispatch } from "@app/redux/store";
import { merchantThunks } from "@app/redux/thunks/merchants.thunk";
import { InCreateMerchantDTO, OutDetailedMerchantDTO } from "@app/api/generated";
import { ROUTES } from "@app/constants/routes";

import { CreateMerchantFormComponent, ICreateMerchantFormValues } from "./create-merchant.form";

export interface ICreateMerchantModalProps {
  onCancel: () => void;
}

const CreateMerchantModal = (props: ICreateMerchantModalProps) => {
  const history = useNavigate();
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const createMerchant = async (values: ICreateMerchantFormValues) => {
    setIsLoading(true);
    const platformId: any = values.platform;
    const newMerchant: InCreateMerchantDTO = {
      name: values.name,
      platform: { id: Number(platformId), name: "", googleExperimentId: "", wctPlatformId: 0 }
    };

    const createdMerchant: OutDetailedMerchantDTO = (await dispatch(
      merchantThunks.createNewMerchant(newMerchant)
    )) as any;

    if (createdMerchant) {
      history(`${ROUTES.MERCHANT_DETAIL}/${createdMerchant.id}`);
    }

    setIsLoading(false);
  };

  return (
    <CreateMerchantFormComponent
      intl={intl}
      onSubmit={createMerchant}
      onCancel={props.onCancel}
      isLoading={isLoading}
    />
  );
};

export { CreateMerchantModal };
