import React from "react";
import clsx from "clsx";

import { SpinningLoader } from "@app/core/spinning-loader/spinning-loader";

import styles from "./page-card.module.scss";

export interface IPageCardProps extends React.HTMLProps<HTMLDivElement> {
  isForm?: boolean;
  isLoading?: boolean;
}

const PageCard = ({ className, isForm, isLoading, children, ...rest }: IPageCardProps): JSX.Element => {
  return (
    <div className={clsx(styles.overview, isForm && styles.isForm, className)} {...rest}>
      {isLoading ? <SpinningLoader /> : children}
    </div>
  );
};

export { PageCard };
