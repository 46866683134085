import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";

import { useAppDispatch } from "@app/redux/store";
import { dealsThunks } from "@app/redux/thunks/deals.thunk";
import { CreateDealDTO, OutDealDTO } from "@app/api/generated";
import { ROUTES } from "@app/constants/routes";

import { CreateDealFormComponent } from "./create-deal.form";

export interface ICreateDealModalProps {
  onCancel: () => void;
}

const CreateDealModal = (props: ICreateDealModalProps) => {
  const history = useNavigate();
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const createDeal = async (values: CreateDealDTO) => {
    setIsLoading(true);

    const newDeal: OutDealDTO = (await dispatch(
      dealsThunks.createNewDeal(
        values.platform.id,
        values.name,
        values.ean!,
        values.merchant!,
        values.live,
        values.createdBy
      )
    )) as any;

    if (newDeal) {
      history(`${ROUTES.DEAL_DETAIL}/${newDeal.id}`);
    }

    setIsLoading(false);
  };

  return <CreateDealFormComponent intl={intl} onSubmit={createDeal} onCancel={props.onCancel} isLoading={isLoading} />;
};

export { CreateDealModal };
