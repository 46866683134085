import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";

import { ClickableComponent, ResourceTextComponent } from "@app/core";
import BinIcon from "@assets/icons/rounded/bin.svg";

import styles from "./delete-modal-component.module.scss";

export interface IDeleteModalProps {
  onCancel: () => void;
  onDelete: () => void;
}

const DeleteModal = (props: IDeleteModalProps) => {
  const [timer, setTimer] = useState<number>(3);
  const intl = useIntl();

  useEffect(() => {
    let interval: any = null;

    interval = setInterval(() => {
      setTimer((t) => t - 1);
    }, 1000);
    if (timer === 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timer]);

  return (
    <div className={styles.deleteModal}>
      <div className={styles.description}>
        <ResourceTextComponent resourceKey="deleteModalComponent.deleteWarning" />
      </div>
      <div>
        <div className={styles.buttons}>
          <span className={styles.firstButton}>
            <ClickableComponent
              title={intl.formatMessage({ id: "deleteModalComponent.cancel" })}
              onClick={props.onCancel}
              height={48}
              variant="primary-inverted"
            />
          </span>
          <span>
            <ClickableComponent
              buttonType="submit"
              title={`${intl.formatMessage({ id: "deleteModalComponent.delete" })} ${
                timer !== 0 ? `( ${timer.toString()} )` : ""
              }`}
              variant="tertiary"
              height={48}
              iconFillColor="currentColor"
              icon={BinIcon}
              iconPosition="left"
              iconSize="20px"
              onClick={() => {
                props.onDelete();
              }}
              disabled={timer !== 0}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export { DeleteModal };
