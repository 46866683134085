/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from "react";
import clsx from "clsx";

import style from "./text-field-component.module.scss";
import { IconComponent } from "../icon";
import { FormLabelComponent, IFormLabelComponentProps } from "../form-label";

export interface ITextFieldProps {
  autoFocus?: boolean;
  errorMessage?: string | false;
  fullWidth?: boolean;
  helperText?: string;
  id?: string;
  maxCharacters?: number;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onChange?(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void;
  placeholder?: string;
  type?: "text" | "email" | "password" | "textarea";
  variant?: "primary" | "secondary";
  value?: any;
  isTextArea?: boolean;
  height?: number;
  width?: number;
  icon?: any;
  disabled?: boolean;
  label?: IFormLabelComponentProps;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  className?: string;
}

const TextFieldComponent = (props: ITextFieldProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const errorMessage = props.errorMessage || props.label?.errorMessage;

  const onBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsFocused(false);

    props.onBlur?.(e);
  };

  const getBorderColor = () => {
    if (errorMessage) {
      return "#e10a14";
    }

    if (isFocused) {
      return "#1c1c1c";
    }

    return "#e0e0e0";
  };

  return (
    <div
      style={{ width: props.fullWidth ? "100%" : "auto" }}
      className={clsx(
        style["form-field"],
        isFocused && style["form-field__focused"],
        props.variant === "secondary" && isFocused && !errorMessage && style.secondary,
        errorMessage && style.error,
        props.value && props.value.length > 0 && style["not-empty"],
        isHovered && style.hovered,
        props.className
      )}
    >
      {props.label && <FormLabelComponent {...props.label} />}

      <div className={style["form-field__control"]}>
        {props.icon && (
          <div className={style.searchIcon}>
            <IconComponent icon={props.icon} size="22px" />
          </div>
        )}
        {!props.isTextArea ? (
          <input
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onChange={props.onChange}
            onFocus={() => setIsFocused(true)}
            onKeyDown={props.onKeyDown}
            onBlur={onBlur}
            autoFocus={props.autoFocus}
            value={props.value || ""}
            id={props.id}
            maxLength={props.maxCharacters}
            placeholder={props.placeholder}
            type={props.type || "text"}
            className={clsx(style["form-field__input"], props.icon && style.hasIcon, props.disabled && style.disabled)}
            style={{
              borderColor: getBorderColor(),
              height: props.height ? props.height : 40,
              color: errorMessage ? "#e10a14" : "#222222",
              width: props.width && props.width
            }}
            disabled={props.disabled}
          />
        ) : (
          <textarea
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onChange={props.onChange}
            onKeyDown={props.onKeyDown}
            onFocus={() => setIsFocused(true)}
            onBlur={onBlur}
            placeholder={props.placeholder}
            autoFocus={props.autoFocus}
            maxLength={props.maxCharacters}
            value={props.value || ""}
            id={props.id}
            className={clsx(style["form-field__input"], props.icon && style.hasIcon, props.disabled && style.disabled)}
            style={{
              resize: "vertical",
              height: props.height ? props.height.toString().concat("px") : "100px",
              borderColor: getBorderColor(),
              width: props.width && props.width
            }}
            disabled={props.disabled}
          />
        )}
      </div>

      {(props.maxCharacters || errorMessage || props.helperText) && (
        <div className={style["form-field__extra"]}>
          {props.maxCharacters && !errorMessage && !props.helperText && <div />}
          {!errorMessage && props.helperText && (
            <span className={style["form-field__extra__helper"]}>{props.helperText}</span>
          )}
          {errorMessage && <span className={style["form-field__extra__error"]}>{errorMessage}</span>}
          {props.maxCharacters && (
            <span className={style["form-field__extra__character-counter"]}>{`${
              props.value ? props.value.length : "0"
            } / ${props.maxCharacters}`}</span>
          )}
        </div>
      )}
    </div>
  );
};

export { TextFieldComponent };
