import * as React from "react";
import { useLocation, Navigate } from "react-router-dom";

import JWTToken from "@app/authorization/jwt-token";
import { ROUTES } from "@app/constants/routes";

interface IRequireAuthProps {
  children: JSX.Element;
  redirectTo?: string;
}

export const RequireAuth = ({ children, redirectTo = ROUTES.LOGIN }: IRequireAuthProps) => {
  const location = useLocation();
  const isAuthenticated = !JWTToken.checkTokenIsExpired("refreshToken");

  return isAuthenticated ? children : <Navigate to={{ pathname: redirectTo }} state={{ from: location }} replace />;
};
