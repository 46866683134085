import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";

import Plus from "@assets/icons/rounded/add.svg";
import Search from "@assets/icons/rounded/search.svg";
import { useDebounce } from "@app/util/use-debounce";
import { ClickableComponent, TextFieldComponent } from "@app/core";
import { SelectComponent } from "@app/core/select";
import { useAppDispatch, useAppSelector } from "@app/redux/store";
import { productsActions } from "@app/redux/reducers/products";
import { getTranslatedPlatforms } from "@app/constants/platform";
import { getTranslatedStatusses } from "@app/constants/status";
import { ROUTES } from "@app/constants/routes";

import styles from "./product-filter-bar-component.module.scss";

const ProductFilterBar = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const { currentSearch, currentStatus, currentPlatform } = useAppSelector((state) => state.products);
  const [search, setSearch] = useState<string>(currentSearch || "");
  const debouncedSearchTerm = useDebounce(search, 500);

  const translatedPlatforms = getTranslatedPlatforms(intl);
  const translatedStatusses = getTranslatedStatusses(intl);

  useEffect(() => {
    dispatch(productsActions.setCurrentSearch(debouncedSearchTerm || undefined));
  }, [debouncedSearchTerm]);

  const onPlatformChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = Number(e.target.value);
    dispatch(productsActions.setCurrentPlatform(value || undefined));
  };

  const onStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = Number(e.target.value);
    dispatch(productsActions.setCurrentStatus(value || undefined));
  };

  return (
    <div className={styles.filterBar}>
      <div className={styles.searchField}>
        <TextFieldComponent
          height={40}
          placeholder={intl.formatMessage({ id: "products.overview.filters.search.placeholder" })}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          type="text"
          id="search"
          icon={Search}
          width={318}
        />
      </div>

      <SelectComponent
        onChange={onStatusChange}
        value={currentStatus}
        options={[
          { id: 0, name: intl.formatMessage({ id: "general.select.status.placeholder" }) },
          ...translatedStatusses
        ]}
        autoWidth
      />
      <SelectComponent
        onChange={onPlatformChange}
        value={currentPlatform}
        options={[
          { id: 0, name: intl.formatMessage({ id: "general.select.platforms.placeholder" }) },
          ...translatedPlatforms
        ]}
        autoWidth
      />
      <ClickableComponent
        title={intl.formatMessage({ id: "products.overview.button.add" })}
        height={40}
        icon={Plus}
        iconSize="20px"
        iconFillColor="#ffffff"
        iconPosition="left"
        href={ROUTES.PRODUCTS_NEW}
      />
    </div>
  );
};

export { ProductFilterBar };
