export enum storeStatusType {
  "live" = 1,
  "offline" = 2
}

export type storeStatusType2 = {
  id: number;
  name: string;
};

export const storeStatus: storeStatusType2[] = [
  { id: 1, name: "global.status.live" },
  { id: 2, name: "global.status.offline" }
];

export const editStatus: storeStatusType2[] = [
  { id: 1, name: "global.editStatus.published" },
  { id: 2, name: "global.editStatus.merchant" }
];
