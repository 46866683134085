import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";

import { OutBannerDTO } from "@app/api/generated";
import { useAppDispatch } from "@app/redux/store";
import { bannersThunks } from "@app/redux/thunks/banners.thunk";
import { ROUTES } from "@app/constants/routes";

import { CreateBannerFormComponent, ICreateBannerFormValues } from "./create-banner.form";

export interface ICreateBannerModalProps {
  onCancel: () => void;
}

const CreateBannerModal = (props: ICreateBannerModalProps) => {
  const history = useNavigate();
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const createBanner = async (values: ICreateBannerFormValues) => {
    setIsLoading(true);

    const newBanner: OutBannerDTO = (await dispatch(
      bannersThunks.createNewBanner(Number(values.platform), values.name)
    )) as any;

    if (newBanner) {
      history(`${ROUTES.BANNER_DETAIL}/${newBanner.id}`);
    }

    setIsLoading(false);
  };

  return (
    <CreateBannerFormComponent intl={intl} onSubmit={createBanner} onCancel={props.onCancel} isLoading={isLoading} />
  );
};

export { CreateBannerModal };
