import React, { useState } from "react";
import { useIntl } from "react-intl";

import Edit from "@assets/icons/rounded/edit.svg";
import Copy from "@assets/icons/rounded/copy.svg";

import { ClickableComponent } from "@app/core";
import { SelectComponent } from "@app/core/select";
import { statusDealOptions } from "@app/constants/deal";
import { externalDealFiltersThunk } from "@app/redux/thunks/external-deal-filters.thunk";
import { useAppDispatch } from "@app/redux";

import styles from "./external-deal-filter-options-bar-component.module.scss";
import { SelectedFilter } from "../external-deal-filter-overview.component";

export interface IEdfOverviewOptionsProps {
  selectedFilters: SelectedFilter[];
  onEditStatus: () => void;
  onDuplicate: () => void;
  onEdit: () => void;
}

const ExternalDealFilterOptionsBar = (props: IEdfOverviewOptionsProps) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [selectedStatus, setSelectedStatus] = useState<number | null>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onStatusChange = (e: React.ChangeEvent<any>) => {
    if (Number(e.target.value) === 0) {
      setSelectedStatus(null);
    }
    setSelectedStatus(Number(e.target.value));
  };

  const onEditStatus = async () => {
    setIsLoading(true);

    await dispatch(
      externalDealFiltersThunk.patchLiveManyEdfs({
        filterIds: props.selectedFilters.map((filter) => {
          return { id: filter.id };
        }),
        live: selectedStatus === 1
      })
    );

    setIsLoading(false);
    props.onEditStatus();
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.amount}>
        {intl.formatMessage(
          { id: "externalDealFilters.overview.selectedFilters" },
          { amount: props.selectedFilters.length }
        )}
      </div>

      <SelectComponent
        height={36}
        onChange={onStatusChange}
        options={statusDealOptions}
        value={selectedStatus}
        disabled={props.selectedFilters.length === 0}
      />
      <ClickableComponent
        buttonType="submit"
        title={intl.formatMessage({ id: "dealOverviewOptionsBarComponent.button.editDeals" })}
        height={36}
        variant="primary-inverted"
        onClick={onEditStatus}
        disabled={props.selectedFilters.length === 0 || isLoading || selectedStatus === 0}
      />
      <div className={styles.separator} />

      <ClickableComponent
        title={intl.formatMessage({ id: "externalDealFilters.overview.button.edit" })}
        icon={Edit}
        height={36}
        variant="primary-inverted"
        iconSize="20px"
        iconFillColor="currentColor"
        iconPosition="left"
        onClick={props.onEdit}
        disabled={props.selectedFilters.length === 0 || isLoading}
      />
      <ClickableComponent
        title={intl.formatMessage({ id: "externalDealFilters.overview.button.duplicate" })}
        variant="primary-inverted"
        icon={Copy}
        height={36}
        iconSize="18px"
        iconFillColor="currentColor"
        iconPosition="left"
        onClick={props.onDuplicate}
        disabled={props.selectedFilters.length === 0 || isLoading}
      />
    </div>
  );
};

export { ExternalDealFilterOptionsBar };
