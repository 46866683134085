import * as React from "react";
import "./toast-component.scss";
import { ToastContainer, Slide, toast, ToastContainerProps } from "react-toastify";

interface IToastProps extends ToastContainerProps {}

const ToastComponent = (_props: IToastProps) => (
  <ToastContainer transition={Slide} position={toast.POSITION.TOP_CENTER} draggable />
);

export { ToastComponent };
