import React, { useState, useEffect } from "react";
import { IntlShape, useIntl } from "react-intl";

import { useDebounce } from "@app/util/use-debounce";
import { TextFieldComponent, ClickableComponent } from "@app/core";
import Plus from "@assets/icons/rounded/add.svg";
import Search from "@assets/icons/rounded/search.svg";
import { SelectComponent } from "@app/core/select";
import { ModalComponent } from "@app/core/modal";
import { bannerStatusType2 } from "@app/api/core/banner/banner";
import { platformTranslationKeys } from "@app/constants/platform";
import { IFilterType } from "@app/constants/filter";
import { bannerStatusSelectValues } from "@app/constants/banner";
import { useAppDispatch, useAppSelector } from "@app/redux/store";
import { setCurrentPlatform, setCurrentSearch, setCurrentStatus } from "@app/redux/reducers/banners";

import { CreateBannerModal } from "../create-banner-modal";
import styles from "./banner-filter-bar-component.module.scss";

const BannerFilterBar = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { currentSearch, currentPlatform, currentStatus } = useAppSelector((state) => state.banners);

  const [search, setSearch] = useState<string>(currentSearch || "");
  const [isAddBannerOpen, setIsAddBannerOpen] = useState<boolean>(false);
  const debouncedSearchTerm = useDebounce(search, 500);

  const translatedPlatforms = getTranslatedPlatforms(intl);
  const translatedBannerStatus = getTranslatedBannerStatus(intl);

  useEffect(() => {
    dispatch(setCurrentSearch(debouncedSearchTerm || undefined));
  }, [debouncedSearchTerm]);

  const onStatusChange = (e: React.ChangeEvent<any>) => {
    const value = Number(e.target.value);
    dispatch(setCurrentStatus(value || undefined));
  };

  const onPlatformChange = (e: React.ChangeEvent<any>) => {
    const value = Number(e.target.value);
    dispatch(setCurrentPlatform(value || undefined));
  };

  return (
    <div className={styles.bannerFilterBar}>
      <div className={styles.searchField}>
        <TextFieldComponent
          height={40}
          placeholder={intl.formatMessage({ id: "bannerFilterBar.input.search.placeholder" })}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          type="text"
          id="search"
          icon={Search}
          width={318}
        />
      </div>

      <SelectComponent onChange={onStatusChange} value={currentStatus} options={[...translatedBannerStatus]} />

      <SelectComponent
        onChange={onPlatformChange}
        value={currentPlatform}
        options={[
          { id: 0, name: intl.formatMessage({ id: "bannerFilterBar.input.platform.all" }) },
          ...translatedPlatforms
        ]}
      />

      <ClickableComponent
        title={intl.formatMessage({ id: "bannerFilterBar.button.addBanner" })}
        height={40}
        icon={Plus}
        iconSize="20px"
        iconFillColor="#ffffff"
        iconPosition="left"
        onClick={() => setIsAddBannerOpen(true)}
      />

      <ModalComponent
        title={intl.formatMessage({ id: "bannerFilterBar.button.addBanner" })}
        isModalOpen={isAddBannerOpen}
        onCloseModal={() => {
          setIsAddBannerOpen(false);
        }}
        variant="big"
      >
        <CreateBannerModal onCancel={() => setIsAddBannerOpen(false)} />
      </ModalComponent>
    </div>
  );
};

export { BannerFilterBar };

const getTranslatedPlatforms = (intl: IntlShape): IFilterType[] =>
  platformTranslationKeys.map((untranslatedPlatform) => ({
    id: untranslatedPlatform.id,
    name: intl.formatMessage({ id: untranslatedPlatform.name })
  }));

const getTranslatedBannerStatus = (intl: IntlShape): bannerStatusType2[] =>
  bannerStatusSelectValues.map((bannerStatus) => ({
    id: bannerStatus.id,
    name: intl.formatMessage({ id: bannerStatus.name })
  }));
