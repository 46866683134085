import React, { useState } from "react";
import { useIntl } from "react-intl";

import BinIcon from "@assets/icons/rounded/bin.svg";
import { ClickableComponent } from "@app/core";
import { SelectComponent } from "@app/core/select";
import { statusDealOptions, IDealEditOptions } from "@app/constants/deal";

import styles from "./deal-overview-options-bar-component.module.scss";

export interface IDealOverviewOptionsBarProps {
  isSelected: number[];
  onEdit: (changes: IDealEditOptions) => void;
  onRemove: () => void;
}

const DealOverviewOptionsBar = (props: IDealOverviewOptionsBarProps) => {
  const [selectedStatus, setSelectedStatus] = useState<number | null>(0);
  const intl = useIntl();

  const onStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (Number(e.target.value) === 0) {
      setSelectedStatus(null);
    }
    setSelectedStatus(Number(e.target.value));
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.editSection}>
        <SelectComponent height={36} onChange={onStatusChange} options={statusDealOptions} value={selectedStatus} />
        <ClickableComponent
          buttonType="submit"
          title={intl.formatMessage({ id: "dealOverviewOptionsBarComponent.button.editDeals" })}
          height={36}
          onClick={() => props.onEdit({ status: selectedStatus })}
          disabled={props.isSelected.length === 0}
        />
      </div>
      <ClickableComponent
        buttonType="submit"
        title={intl.formatMessage({ id: "dealOverviewOptionsBarComponent.button.deleteDeals" })}
        height={36}
        onClick={props.onRemove}
        icon={BinIcon}
        iconSize="18px"
        iconPosition="left"
        iconStyle="filled"
        variant="tertiary"
        disabled={props.isSelected.length === 0}
      />
    </div>
  );
};

export { DealOverviewOptionsBar };
