import { trim } from "lodash";
import slugify from "slugify";

export const basicSlugify = (value?: string, allowSlashes?: boolean): string => {
  const slugifyProps = { lower: true, strict: false, locale: "nl" };
  let newValue = trim(value, "/");
  newValue = replacePlus(newValue);
  newValue = tryDecode(newValue);

  if (allowSlashes && newValue.includes("/")) {
    newValue = newValue
      .split("/")
      .map((slug) => slugify(slug, slugifyProps))
      .join("/");
  } else {
    newValue = slugify(newValue, slugifyProps);
  }

  return newValue;
};

const replacePlus = (value?: string): string => {
  const plusRegex = /\+/g;
  const dotRegex = /\./g;

  const replacementChar = "-";

  let newValue = value || "";

  newValue = newValue.replace(plusRegex, replacementChar);
  newValue = newValue.replace(dotRegex, replacementChar);

  return newValue;
};

const tryDecode = (value?: string): string => {
  try {
    return decodeURIComponent(value || "");
  } catch {
    return decodeURIComponent(encodeURI(value || ""));
  }
};
