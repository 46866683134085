import React, { useState } from "react";
import { withFormik, FormikProps, Form } from "formik";
import { IntlShape } from "react-intl";

import { TextFieldComponent } from "@app/core/text-field";
import { ClickableComponent } from "@app/core/clickable";
import { ResourceTextComponent } from "@app/core/resource-text";
import { newPasswordFormValidation } from "@app/util/form-validation-messages";

import styles from "./change-password-form.module.scss";

interface IFormValues {
  passwordFormCurrentPassword: string;
  passwordFormNewPassword: string;
  passwordFormNewPasswordConfirmation: string;
}

interface IOtherProps {
  intl: IntlShape;
}

const InnerForm = (props: IOtherProps & FormikProps<IFormValues>) => {
  const { touched, errors } = props;

  const [formIsTouched, setFormIsTouched] = useState(false);

  const handleChange = (values: any) => {
    props.handleChange(values);

    if (!formIsTouched) {
      setFormIsTouched(true);
    }
  };

  return (
    <Form>
      <div className={styles.dataBlock}>
        <div className={styles.label}>
          <span>
            <ResourceTextComponent resourceKey="userAccountDetail.loginDetails.changePassword.title" />
          </span>

          <span className={styles.description}>
            <ResourceTextComponent resourceKey="userAccountDetail.loginDetails.changePassword.description" />
          </span>
        </div>

        <div className={styles.fieldsContainer}>
          <div className={styles.fields}>
            <div className={styles.formField}>
              <TextFieldComponent
                height={40}
                value={props.values.passwordFormCurrentPassword}
                onChange={handleChange}
                id="passwordFormCurrentPassword"
                type="password"
                errorMessage={touched.passwordFormCurrentPassword && errors.passwordFormCurrentPassword}
                label={{
                  label: props.intl.formatMessage({
                    id: "userAccountDetail.loginDetails.changePassword.input.currentPassword.label"
                  }),
                  tooltip: props.intl.formatMessage({
                    id: "userAccountDetail.loginDetails.changePassword.input.currentPassword.tooltip"
                  })
                }}
              />
            </div>
          </div>
          <div className={styles.fields}>
            <div className={styles.formField}>
              <TextFieldComponent
                height={40}
                value={props.values.passwordFormNewPassword}
                onChange={handleChange}
                id="passwordFormNewPassword"
                type="password"
                errorMessage={touched.passwordFormNewPassword && errors.passwordFormNewPassword}
                label={{
                  label: props.intl.formatMessage({
                    id: "userAccountDetail.loginDetails.changePassword.input.newPassword.label"
                  }),
                  tooltip: props.intl.formatMessage({
                    id: "userAccountDetail.loginDetails.changePassword.input.newPassword.tooltip"
                  })
                }}
              />
            </div>
            <div className={styles.formField}>
              <TextFieldComponent
                height={40}
                value={props.values.passwordFormNewPasswordConfirmation}
                onChange={handleChange}
                id="passwordFormNewPasswordConfirmation"
                type="password"
                errorMessage={touched.passwordFormNewPasswordConfirmation && errors.passwordFormNewPasswordConfirmation}
                label={{
                  label: props.intl.formatMessage({
                    id: "userAccountDetail.loginDetails.changePassword.input.newPasswordConfirmation.label"
                  }),
                  tooltip: props.intl.formatMessage({
                    id: "userAccountDetail.loginDetails.changePassword.input.newPasswordConfirmation.tooltip"
                  })
                }}
              />
            </div>
          </div>
          <div className={styles.fields}>
            <div className={styles.formField}>
              <div className={styles.buttonSubmit}>
                <ClickableComponent
                  buttonType="submit"
                  title={props.intl.formatMessage({
                    id: "userAccountDetail.loginDetails.changePassword.submit"
                  })}
                  disabled={!formIsTouched}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

interface IMyFormProps {
  initialEmail?: string;
  intl: IntlShape;
  onSubmit: (values: any) => void;
}

export const ChangePasswordForm = withFormik<IMyFormProps, IFormValues>({
  mapPropsToValues: () => ({
    passwordFormCurrentPassword: "",
    passwordFormNewPassword: "",
    passwordFormNewPasswordConfirmation: ""
  }),

  validate: (values: IFormValues, props: IMyFormProps) => {
    const errors: any = {};
    const newPasswordValidation = newPasswordFormValidation(values.passwordFormNewPassword, true);
    const newPasswordConfirmationValidation = newPasswordFormValidation(
      values.passwordFormNewPasswordConfirmation,
      true
    );

    if (!values.passwordFormCurrentPassword) {
      errors.passwordFormCurrentPassword = props.intl.formatMessage({
        id: "general.formValidation.password.notEmpty"
      });
    }

    if (newPasswordValidation) {
      errors.passwordFormNewPassword = props.intl.formatMessage({
        id: newPasswordValidation
      });
    }

    if (newPasswordConfirmationValidation) {
      errors.passwordFormNewPasswordConfirmation = props.intl.formatMessage({
        id: newPasswordConfirmationValidation
      });
    }

    if (values.passwordFormNewPassword !== values.passwordFormNewPasswordConfirmation) {
      errors.passwordFormNewPasswordConfirmation = props.intl.formatMessage({
        id: "general.formValidation.newPassword.noMatch"
      });
    }

    return errors;
  },

  handleSubmit: (values, bag) => {
    bag.props.onSubmit(values);
    bag.resetForm();
  }
})(InnerForm);
