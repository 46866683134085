/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  CreateProductDTO,
  CreateProductDTOFromJSON,
  CreateProductDTOToJSON,
  InProductDTO,
  InProductDTOFromJSON,
  InProductDTOToJSON,
  OutProductDTO,
  OutProductDTOFromJSON,
  OutProductDTOToJSON,
  OutProductExtendedViewDTO,
  OutProductExtendedViewDTOFromJSON,
  OutProductExtendedViewDTOToJSON,
  ProductListResponseDTO,
  ProductListResponseDTOFromJSON,
  ProductListResponseDTOToJSON
} from "../models";

export interface ProductsExtendedGetRequest {
  take: number;
  skip: number;
  searchQuery?: string;
  platformId?: number;
  live?: string;
}

export interface ProductsGetRequest {
  take: number;
  skip: number;
  platformId: number;
}

export interface ProductsIdDeleteRequest {
  id: number;
}

export interface ProductsIdGetRequest {
  extendedView: boolean;
  id: number;
}

export interface ProductsIdPutRequest {
  id: number;
  inProductDTO: InProductDTO;
}

export interface ProductsPostRequest {
  createProductDTO: CreateProductDTO;
}

export interface ProductsSlugProductSlugGetRequest {
  extendedView: boolean;
  platformId: number;
  productSlug: string;
}

/**
 * no description
 */
export class ProductsApi extends runtime.BaseAPI {
  /**
   */
  async productsExtendedGetRaw(
    requestParameters: ProductsExtendedGetRequest
  ): Promise<runtime.ApiResponse<ProductListResponseDTO>> {
    if (requestParameters.take === null || requestParameters.take === undefined) {
      throw new runtime.RequiredError(
        "take",
        "Required parameter requestParameters.take was null or undefined when calling productsExtendedGet."
      );
    }

    if (requestParameters.skip === null || requestParameters.skip === undefined) {
      throw new runtime.RequiredError(
        "skip",
        "Required parameter requestParameters.skip was null or undefined when calling productsExtendedGet."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.searchQuery !== undefined) {
      queryParameters["searchQuery"] = requestParameters.searchQuery;
    }

    if (requestParameters.platformId !== undefined) {
      queryParameters["platformId"] = requestParameters.platformId;
    }

    if (requestParameters.live !== undefined) {
      queryParameters["live"] = requestParameters.live;
    }

    if (requestParameters.take !== undefined) {
      queryParameters["take"] = requestParameters.take;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/products/extended`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => ProductListResponseDTOFromJSON(jsonValue));
  }

  /**
   */
  async productsExtendedGet(
    take: number,
    skip: number,
    searchQuery?: string,
    platformId?: number,
    live?: string
  ): Promise<ProductListResponseDTO> {
    const response = await this.productsExtendedGetRaw({
      take: take,
      skip: skip,
      searchQuery: searchQuery,
      platformId: platformId,
      live: live
    });
    return await response.value();
  }

  /**
   */
  async productsGetRaw(requestParameters: ProductsGetRequest): Promise<runtime.ApiResponse<ProductListResponseDTO>> {
    if (requestParameters.take === null || requestParameters.take === undefined) {
      throw new runtime.RequiredError(
        "take",
        "Required parameter requestParameters.take was null or undefined when calling productsGet."
      );
    }

    if (requestParameters.skip === null || requestParameters.skip === undefined) {
      throw new runtime.RequiredError(
        "skip",
        "Required parameter requestParameters.skip was null or undefined when calling productsGet."
      );
    }

    if (requestParameters.platformId === null || requestParameters.platformId === undefined) {
      throw new runtime.RequiredError(
        "platformId",
        "Required parameter requestParameters.platformId was null or undefined when calling productsGet."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.take !== undefined) {
      queryParameters["take"] = requestParameters.take;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    if (requestParameters.platformId !== undefined) {
      queryParameters["platformId"] = requestParameters.platformId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/products`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => ProductListResponseDTOFromJSON(jsonValue));
  }

  /**
   */
  async productsGet(take: number, skip: number, platformId: number): Promise<ProductListResponseDTO> {
    const response = await this.productsGetRaw({ take: take, skip: skip, platformId: platformId });
    return await response.value();
  }

  /**
   */
  async productsIdDeleteRaw(requestParameters: ProductsIdDeleteRequest): Promise<runtime.ApiResponse<boolean>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling productsIdDelete."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/products/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   */
  async productsIdDelete(id: number): Promise<boolean> {
    const response = await this.productsIdDeleteRaw({ id: id });
    return await response.value();
  }

  /**
   */
  async productsIdGetRaw(
    requestParameters: ProductsIdGetRequest
  ): Promise<runtime.ApiResponse<OutProductExtendedViewDTO>> {
    if (requestParameters.extendedView === null || requestParameters.extendedView === undefined) {
      throw new runtime.RequiredError(
        "extendedView",
        "Required parameter requestParameters.extendedView was null or undefined when calling productsIdGet."
      );
    }

    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling productsIdGet."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.extendedView !== undefined) {
      queryParameters["extendedView"] = requestParameters.extendedView;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/products/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => OutProductExtendedViewDTOFromJSON(jsonValue));
  }

  /**
   */
  async productsIdGet(extendedView: boolean, id: number): Promise<OutProductExtendedViewDTO> {
    const response = await this.productsIdGetRaw({ extendedView: extendedView, id: id });
    return await response.value();
  }

  /**
   */
  async productsIdPutRaw(requestParameters: ProductsIdPutRequest): Promise<runtime.ApiResponse<OutProductDTO>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling productsIdPut."
      );
    }

    if (requestParameters.inProductDTO === null || requestParameters.inProductDTO === undefined) {
      throw new runtime.RequiredError(
        "inProductDTO",
        "Required parameter requestParameters.inProductDTO was null or undefined when calling productsIdPut."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/products/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: InProductDTOToJSON(requestParameters.inProductDTO)
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => OutProductDTOFromJSON(jsonValue));
  }

  /**
   */
  async productsIdPut(id: number, inProductDTO: InProductDTO): Promise<OutProductDTO> {
    const response = await this.productsIdPutRaw({ id: id, inProductDTO: inProductDTO });
    return await response.value();
  }

  /**
   */
  async productsPostRaw(requestParameters: ProductsPostRequest): Promise<runtime.ApiResponse<OutProductDTO>> {
    if (requestParameters.createProductDTO === null || requestParameters.createProductDTO === undefined) {
      throw new runtime.RequiredError(
        "createProductDTO",
        "Required parameter requestParameters.createProductDTO was null or undefined when calling productsPost."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/products`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: CreateProductDTOToJSON(requestParameters.createProductDTO)
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => OutProductDTOFromJSON(jsonValue));
  }

  /**
   */
  async productsPost(createProductDTO: CreateProductDTO): Promise<OutProductDTO> {
    const response = await this.productsPostRaw({ createProductDTO: createProductDTO });
    return await response.value();
  }

  /**
   */
  async productsSlugProductSlugGetRaw(
    requestParameters: ProductsSlugProductSlugGetRequest
  ): Promise<runtime.ApiResponse<OutProductExtendedViewDTO>> {
    if (requestParameters.extendedView === null || requestParameters.extendedView === undefined) {
      throw new runtime.RequiredError(
        "extendedView",
        "Required parameter requestParameters.extendedView was null or undefined when calling productsSlugProductSlugGet."
      );
    }

    if (requestParameters.platformId === null || requestParameters.platformId === undefined) {
      throw new runtime.RequiredError(
        "platformId",
        "Required parameter requestParameters.platformId was null or undefined when calling productsSlugProductSlugGet."
      );
    }

    if (requestParameters.productSlug === null || requestParameters.productSlug === undefined) {
      throw new runtime.RequiredError(
        "productSlug",
        "Required parameter requestParameters.productSlug was null or undefined when calling productsSlugProductSlugGet."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.extendedView !== undefined) {
      queryParameters["extendedView"] = requestParameters.extendedView;
    }

    if (requestParameters.platformId !== undefined) {
      queryParameters["platformId"] = requestParameters.platformId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/products/slug/{productSlug}`.replace(
        `{${"productSlug"}}`,
        encodeURIComponent(String(requestParameters.productSlug))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, (jsonValue) => OutProductExtendedViewDTOFromJSON(jsonValue));
  }

  /**
   */
  async productsSlugProductSlugGet(
    extendedView: boolean,
    platformId: number,
    productSlug: string
  ): Promise<OutProductExtendedViewDTO> {
    const response = await this.productsSlugProductSlugGetRaw({
      extendedView: extendedView,
      platformId: platformId,
      productSlug: productSlug
    });
    return await response.value();
  }
}
