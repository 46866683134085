import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { ClickableComponent, TextFieldComponent } from "@app/core";
import { ModalComponent } from "@app/core/modal";
import Plus from "@assets/icons/rounded/add.svg";
import Search from "@assets/icons/rounded/search.svg";
import { SelectComponent } from "@app/core/select";
import { platformTranslationKeys } from "@app/constants/platform";
import { IFilterType } from "@app/constants/filter";
import { useDebounce } from "@app/util/use-debounce";
import { storeStatus, storeStatusType2 } from "@app/api/core/merchant/merchant";
import { FilterItem } from "@app/api/core/filter/filter-item";
import { mapMerchantToFilterItem } from "@app/api/core/filter/map-filter-item";
import { dealCreatedBySelectValues } from "@app/constants/deal";
import { useAppDispatch, useAppSelector } from "@app/redux/store";
import {
  setCurrentCreatedBy,
  setCurrentPlatform,
  setCurrentSearch,
  setCurrentStatus,
  setCurrentMerchant
} from "@app/redux/reducers/deals";
import { ReactSelect } from "@app/core/react-select/react-select";

import { CreateDealModal } from "../create-deal-modal";
import { getMerchants } from "../deal-detail/components/api-calls";
import styles from "./deal-filter-bar-component.module.scss";

const DealFilterBar = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { currentSearch, currentCreatedBy, currentPlatform, currentMerchant, currentStatus } = useAppSelector(
    (state) => state.deals
  );

  const [search, setSearch] = useState<string>(currentSearch || "");
  const [isAddDealOpen, setIsAddDealOpen] = useState<boolean>(false);
  const debouncedSearchTerm = useDebounce(search, 500);

  const translatedPlatforms = platformTranslationKeys.map((untranslatedPlatform) => {
    return {
      id: untranslatedPlatform.id,
      name: intl.formatMessage({ id: untranslatedPlatform.name })
    } as IFilterType;
  });

  const translatedStoreStatus = storeStatus.map((untranslatedStoreStatus) => {
    return {
      id: untranslatedStoreStatus.id,
      name: intl.formatMessage({ id: untranslatedStoreStatus.name })
    } as storeStatusType2;
  });

  const translatedCreatedBy = dealCreatedBySelectValues.map((untranslatedStoreStatus) => {
    return {
      id: untranslatedStoreStatus.id,
      name: intl.formatMessage({ id: untranslatedStoreStatus.name })
    } as storeStatusType2;
  });

  useEffect(() => {
    dispatch(setCurrentSearch(debouncedSearchTerm || undefined));
  }, [debouncedSearchTerm]);

  const onCreatedByChange = (e: React.ChangeEvent<any>) => {
    const value = Number(e.target.value);
    dispatch(setCurrentCreatedBy(value || undefined));
  };

  const onStatusChange = (e: React.ChangeEvent<any>) => {
    const value = Number(e.target.value);
    dispatch(setCurrentStatus(value || undefined));
  };

  const onPlatformChange = (e: React.ChangeEvent<any>) => {
    const value = Number(e.target.value);
    dispatch(setCurrentPlatform(value || undefined));
  };

  const onMerchantChange = (value: FilterItem | null) => {
    dispatch(setCurrentMerchant(value || undefined));
  };

  const loadMerchants = async (inputValue: string, callback: (options: FilterItem[]) => void) => {
    const showPlatformInLabels = !currentPlatform;
    const merchants = await getMerchants(inputValue, currentPlatform);
    callback(merchants ? merchants.map((merchant) => mapMerchantToFilterItem(merchant, showPlatformInLabels)) : []);
  };

  return (
    <div className={styles.dealFilterBar}>
      <div className={styles.searchField}>
        <TextFieldComponent
          height={40}
          placeholder={intl.formatMessage({ id: "dealFilterBarComponent.searchPlaceholder" })}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          type="text"
          id="search"
          icon={Search}
          width={318}
        />
      </div>

      <SelectComponent onChange={onCreatedByChange} value={currentCreatedBy} options={translatedCreatedBy} autoWidth />

      <SelectComponent
        onChange={onStatusChange}
        value={currentStatus}
        options={[
          { id: 0, name: intl.formatMessage({ id: "dealFilterBarComponent.selectAllStatusses" }) },
          ...translatedStoreStatus
        ]}
        autoWidth
      />

      <div className={styles.merchantSelect}>
        <ReactSelect
          id="merchant"
          isClearable
          value={currentMerchant}
          placeholder={intl.formatMessage({ id: "dealFilterBarComponent.selectAllMerchants" })}
          loadOptions={loadMerchants}
          isAsync
          isMulti={false}
          onChange={onMerchantChange}
        />
      </div>

      <SelectComponent
        onChange={onPlatformChange}
        value={currentPlatform}
        options={[
          { id: 0, name: intl.formatMessage({ id: "dealFilterBarComponent.selectAllPlatforms" }) },
          ...translatedPlatforms
        ]}
        autoWidth
      />

      <ClickableComponent
        title={intl.formatMessage({ id: "dealFilterBarComponent.addDeal" })}
        height={40}
        icon={Plus}
        iconSize="20px"
        iconFillColor="#ffffff"
        iconPosition="left"
        onClick={() => {
          setIsAddDealOpen(true);
        }}
      />

      <ModalComponent
        title={intl.formatMessage({ id: "dealFilterBarComponent.addDeal" })}
        isModalOpen={isAddDealOpen}
        onCloseModal={() => {
          setIsAddDealOpen(false);
        }}
        variant="big"
      >
        <CreateDealModal onCancel={() => setIsAddDealOpen(false)} />
      </ModalComponent>
    </div>
  );
};

export { DealFilterBar };
