import { LightenDarkenColor } from "@app/util/lighten-darken-color";

export const STYLES = {
  COLOR_PRIMARY: "#e10a14",

  COLOR_SECONDARY: "#00631c",
  COLOR_SECONDARY_FADED: "#00a82f",

  COLOR_TERTIARY_BLUE: "#1C96C4",

  COLOR_TINT_GREY_96: "#F5F5F5",
  COLOR_TINT_GREY_89: "#E3E3E3",
  COLOR_TINT_GREY_80: "#CCCCCC",
  COLOR_TINT_GREY_68: "#AEAEAE",
  COLOR_TINT_GREY_43: "#6E6E6E",
  COLOR_TINT_GREY_20: "#333333",
  COLOR_TINT_GREY_14: "#232323",
  COLOR_TINT_GREY_0: "#000000",

  COLOR_INFO_SUCCESS: "#22C683",
  COLOR_INFO_WARNING: "#FF8A00",
  COLOR_INFO_DANGER: "#D93C57",
  COLOR_INFO_DANGER_FADED: LightenDarkenColor("#D93C57", 20),
  COLOR_INFO_DANGER_DARKER: LightenDarkenColor("#D93C57", -10),
  COLOR_INFO_DANGER_DARKEST: LightenDarkenColor("#D93C57", -15),

  COLOR_BACKGROUND: "#F5F5F5",

  GLOBAL_BORDER_COLOR: "#E3E3E3",
  GLOBAL_BORDER_DARK_COLOR: "#e0e0e0",
  GLOBAL_BORDER_STYLE: "1px solid #E3E3E3",
  GLOBAL_BORDER_DARK_STYLE: "1px solid #e0e0e0",
  GLOBAL_SHADOW_STYLE: "0px 3px 0px #E3E3E3",

  GLOBAL_BORDER_RADIUS: "4px",

  FONT_COLOR_MAIN: "#1c1c1c",
  FONT_COLOR_BODY: "#1c1c1c",
  FONT_COLOR_FADED: "#6E6E6E",
  FONT_COLOR_PLACEHOLDER: "#AEAEAE"
};
