import React from "react";
import clsx from "clsx";
import { useIntl } from "react-intl";

import { ClickableComponent } from "@app/core";

import { useInViewport } from "@app/util/use-in-viewport";
import styles from "./form-actions.module.scss";

export interface IFormActionsProps {
  disableSaveButton?: boolean;
  isSubmittingValues?: boolean;
  onCancel: (isDirtyCheck: boolean) => void;
  onSubmit: () => void;
  children?: React.ReactNode;
  hiddenSubmitRef?: React.RefObject<HTMLButtonElement>;
}

const FormActions = ({
  disableSaveButton,
  isSubmittingValues,
  onCancel,
  onSubmit,
  hiddenSubmitRef,
  children
}: IFormActionsProps) => {
  const { ref, inViewport } = useInViewport();
  const intl = useIntl();

  return (
    <div ref={ref}>
      <div className={clsx(styles.formActions, !disableSaveButton && !inViewport && styles.fixed)}>
        <div className={clsx(styles.wrapper)}>
          <div className={styles.content}>
            {children}
            <div className={styles.buttons}>
              <ClickableComponent
                variant="primary-inverted"
                title={intl.formatMessage({ id: "general.form.actions.cancel.title" })}
                buttonType="button"
                height={48}
                onClick={() => onCancel(false)}
                disabled={isSubmittingValues}
              />
              <ClickableComponent
                buttonType="button"
                title={intl.formatMessage({ id: "general.form.actions.submit.title" })}
                height={48}
                onClick={onSubmit}
                disabled={disableSaveButton || isSubmittingValues}
              />
              <button
                style={{ display: "none" }}
                onClick={onSubmit}
                type="button"
                ref={hiddenSubmitRef}
                aria-label={intl.formatMessage({ id: "general.form.actions.submit.title" })}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { FormActions };
