import React, { useEffect, useState } from "react";
import { withFormik, FormikProps, FormikErrors, Form } from "formik";
import { IntlShape } from "react-intl";
import { toast } from "react-toastify";

import { TextFieldComponent, ClickableComponent, IconComponent } from "@app/core";
import { SelectComponent } from "@app/core/select";
import { platformType, platformTranslationKeys, platformLocales } from "@app/constants/platform";
import { IFilterType } from "@app/constants/filter";
import { FilterItem } from "@app/api/core/filter/filter-item";
import { mapMerchantToFilterItem } from "@app/api/core/filter/map-filter-item";
import { useRefState } from "@app/util/use-ref-state";
import { getMerchants } from "@app/modules/deal-detail/components/api-calls";
import { CreateFeaturedCategoriesDTO } from "@app/api/generated/models/CreateFeaturedCategoriesDTO";
import { basicSlugify } from "@app/util/slugify";
import { getFilterItemFormErrors } from "@app/util/get-filter-item-form-errors";
import { ReactSelect } from "@app/core/react-select/react-select";
import { FormGroup } from "@app/core/form-group/form-group";
import { FormRow } from "@app/core/form-row/form-row";
import CopyIcon from "@assets/icons/rounded/copy.svg";
import { FormField } from "@app/core/form-field/form-field";
import { SlugifyTextField } from "@app/core/slugify-text-field/slugify-text-field";

import styles from "./create-featured-category-modal-component.module.scss";

interface ICreateCategoryFormValues {
  platform: platformType;
  categoryTitle: string;
  merchant?: FilterItem;
  trackingUrl: string;
  slug: string;
}

let outLinkValue = "";

const InnerForm = (props: IFormProps & FormikProps<ICreateCategoryFormValues>) => {
  const { touched, errors, setFieldValue } = props;
  const [outLink, setOutLink] = useState<string>("");
  const [isCustomSlugChange, setIsCustomSlugChange] = useState<boolean>(false);
  const platformRef = useRefState(props.values.platform);

  const onCustomChange = (value: any, id: string) => {
    props.handleChange(value);
    setFieldValue(id, value);
  };

  useEffect(() => {
    generateOutLink();
  }, []);

  useEffect(() => {
    generateOutLink();
  }, [props.values.platform, props.values.slug, props.values.categoryTitle, props.values.merchant]);

  const translatedPlatforms = platformTranslationKeys.map((untranslatedPlatform) => {
    return {
      id: untranslatedPlatform.id,
      name: props.intl.formatMessage({ id: untranslatedPlatform.name })
    } as IFilterType;
  });

  const loadMerchants = async (inputValue: string, callback: (options: FilterItem[]) => void) => {
    const merchants = await getMerchants(inputValue, platformRef.current);
    callback(merchants ? merchants.map((merchant) => mapMerchantToFilterItem(merchant)) : []);
  };

  const generateOutLink = () => {
    const locale = platformLocales.find((platformItem) => platformItem.id === Number(props.values.platform));

    const slug = isCustomSlugChange ? props.values.slug : props.values.categoryTitle;

    const outLinkUrl = createOutLink(
      props.values.merchant ? basicSlugify(props.values.merchant.label, true) : "",
      slug ? basicSlugify(slug, true) : "",
      locale?.name
    );

    setOutLink(outLinkUrl);
    outLinkValue = outLinkUrl;
  };

  const handleSlugChange = (
    e?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    disableSlugify?: boolean
  ): void => {
    const value = !e ? props.values.slug : e.currentTarget.value;

    if (e) {
      setIsCustomSlugChange(true);
    }

    props.setFieldValue("slug", disableSlugify ? value : basicSlugify(value, true));
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(outLinkValue);
    toast.success(props.intl.formatMessage({ id: "featuredCategoryDetailForm.copyToClipboard.success" }));
  };

  return (
    <Form>
      <div className={styles.form}>
        <FormGroup hideSeparator>
          <FormRow>
            <FormField>
              <TextFieldComponent
                height={40}
                value={props.values.categoryTitle}
                onChange={props.handleChange}
                id="categoryTitle"
                label={{
                  label: props.intl.formatMessage({ id: "featuredCategoryDetailForm.input.categoryTitle.label" }),
                  errorMessage: touched.categoryTitle === true && errors.categoryTitle
                }}
              />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <ReactSelect
                id="merchant"
                label={{
                  label: props.intl.formatMessage({ id: "featuredCategoryDetailForm.input.merchants.label" }),
                  errorMessage: getFilterItemFormErrors(touched.merchant, errors.merchant)
                }}
                isClearable
                value={props.values.merchant}
                loadOptions={loadMerchants}
                isAsync
                onChange={(e) => onCustomChange(e, "merchant")}
              />
            </FormField>
            <FormField>
              <SelectComponent
                height={40}
                value={props.values.platform}
                onChange={props.handleChange}
                options={translatedPlatforms}
                id="platform"
                label={{
                  label: props.intl.formatMessage({ id: "featuredCategoryDetailForm.input.platform.label" }),
                  errorMessage: touched.platform === true && errors.platform
                }}
              />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <TextFieldComponent
                height={40}
                value={props.values.trackingUrl}
                onChange={props.handleChange}
                id="trackingUrl"
                label={{
                  label: props.intl.formatMessage({ id: "featuredCategoryDetailForm.input.trackingUrl.label" }),
                  tooltip: props.intl.formatMessage({ id: "featuredCategoryDetailForm.input.trackingUrl.tooltip" }),
                  errorMessage: touched.trackingUrl === true && errors.trackingUrl
                }}
              />
            </FormField>

            <FormField>
              <SlugifyTextField
                onChange={(e) => handleSlugChange(e, true)}
                value={isCustomSlugChange ? props.values.slug : props.values.categoryTitle}
                id="slug"
                fullWidth
                onGenerateSlugClick={() => handleSlugChange()}
                onBlur={props.handleBlur}
                label={{
                  label: props.intl.formatMessage({ id: "featuredCategoryDetailForm.input.slug.label" }),
                  tooltip: props.intl.formatMessage({ id: "featuredCategoryDetailForm.input.slug.tooltip" }),
                  errorMessage: touched.slug === true && errors.slug
                }}
                height={40}
              />

              <button type="button" className={styles.slugLink} onClick={() => copyToClipboard()}>
                <IconComponent icon={CopyIcon} size="14px" fillColor="currentColor" />
                {outLink}
              </button>
            </FormField>
          </FormRow>
        </FormGroup>

        <div className={styles.buttons}>
          <ClickableComponent
            buttonType="button"
            title={props.intl.formatMessage({ id: "featuredCategoryDetailForm.button.cancel" })}
            onClick={() => props.onCancel()}
            height={48}
            variant="primary-inverted"
          />

          <ClickableComponent
            buttonType="submit"
            title={props.intl.formatMessage({ id: "featuredCategoryDetailForm.button.add" })}
            height={48}
            disabled={props.isLoading}
          />
        </div>
      </div>
    </Form>
  );
};

interface IFormProps {
  intl: IntlShape;
  isLoading: boolean;
  onCancel: () => void;
  onSubmit: (values: CreateFeaturedCategoriesDTO | undefined) => void;
}

export const CreateCategoryFormComponent = withFormik<IFormProps, ICreateCategoryFormValues>({
  mapPropsToValues: () => ({
    platform: 1,
    categoryTitle: "",
    merchant: undefined,
    trackingUrl: "",
    slug: ""
  }),

  validate: (values: ICreateCategoryFormValues, props) => {
    const errors: FormikErrors<ICreateCategoryFormValues> = {};

    if (!values.platform) {
      errors.platform = props.intl.formatMessage({ id: "featuredCategoryDetailForm.error.platform.required" });
    }
    if (!values.trackingUrl) {
      errors.trackingUrl = props.intl.formatMessage({ id: "featuredCategoryDetailForm.error.trackingUrl.required" });
    }
    if (!values.merchant || !values.merchant?.value) {
      errors.merchant = props.intl.formatMessage({ id: "featuredCategoryDetailForm.error.merchants.required" });
    }

    if (!values.categoryTitle) {
      errors.categoryTitle = props.intl.formatMessage({
        id: "featuredCategoryDetailForm.error.categoryTitle.required"
      });
    }
    if (!values.slug && !values.categoryTitle) {
      errors.slug = props.intl.formatMessage({ id: "featuredCategoryDetailForm.error.slug.required" });
    }

    return errors;
  },

  handleSubmit: (values, bag) => {
    const featuredCategory = mapValuesToFeaturedCategory(values);
    bag.props.onSubmit(featuredCategory);
  }
})(InnerForm);

const mapValuesToFeaturedCategory = (values: ICreateCategoryFormValues) => {
  if (values.merchant) {
    const newFeaturedCategory: CreateFeaturedCategoriesDTO = {
      platform: { id: Number(values.platform), name: "", googleExperimentId: "", wctPlatformId: 0 },
      categoryTitle: values.categoryTitle,
      merchant: values.merchant && { id: Number(values.merchant.value) },
      trackingUrl: values.trackingUrl,
      slug: outLinkValue
    };

    return newFeaturedCategory;
  }
};

export const createOutLink = (merchant?: string, slug?: string, locale?: string) => {
  const baseUrl = "out/category";
  const newSlug = [baseUrl, locale, merchant, slug].filter(Boolean).join("/");

  return `/${newSlug}/`;
};
